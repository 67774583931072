
import { renderToString } from 'react-dom/server';
import Leaflet from "leaflet";
import { Marker } from 'react-leaflet';
import { useTheme } from '@mui/material/styles';
import ContainerMarker from './ContainerMarker';
import TwoWheelerIcon from '@mui/icons-material/TwoWheeler';

type MapMarkerAnkletProps = {
    lat: number;
    lng: number;
}

export default function MotoMarker({ lat, lng }: MapMarkerAnkletProps) {
    const theme = useTheme();

    const htmlAlert = renderToString(<ContainerMarker color={`${theme.palette.primary.main}`}> <TwoWheelerIcon fontSize="small" sx={{ color: '#fff'}} /> </ContainerMarker>)

    return (
        <Marker
            icon={Leaflet.divIcon({
                className: 'custom-svg-icon',
                html: htmlAlert,
                iconAnchor: [0, 0],
                popupAnchor: [-5, -40],
                iconSize: [40, 40],
            })}
            eventHandlers={{
                click: () => {
                    //open(ankler)
                },
                popupclose: () => {
                    //close(null)
                }
            }}
            key={lat}
            position={[
                lat,
                lng
            ]}

        />
    )
}


