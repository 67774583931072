import Grid  from "@mui/material/Grid2";
import React from "react";

export function Ball() {
 return (
    <span style={{borderRadius: '50%', width: '10px', height: '10px', border: '1px solid #575B64', backgroundColor: '#575B64', margin: 1}}></span>
 )
}


export default function HiddenKeyWordTracksCard() {
    return (
        <Grid container sx={{paddingLeft: 2, paddingRight: 2}}>
           <Ball />
           <Ball />
           <Ball />
           <Ball />
           <Ball />
           <Ball />
        </Grid>                   
    )
}
