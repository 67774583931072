import React, { useEffect, useState } from "react";
import {
  UseFormGetValues,
  UseFormRegister,
  Controller,
  UseFormSetValue,
  FieldValues,
  UseFormWatch,
} from "react-hook-form";
import InputField from "../../../Field";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { InputAdornment, IconButton } from "@mui/material";

interface CrudTextFieldProps {
  readonly register: UseFormRegister<any>;
  readonly name: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly control?: any;
  readonly label?: string;
  required: boolean;
  messageError: string;
  value: string;
  readonly setValue: UseFormSetValue<any>;
  disabled: boolean;
  id?: string;
  readonly watch: UseFormWatch<FieldValues>;
  readonly dependent: string | null;
}

export default function PasswordField({
  label,
  register,
  control = {},
  name,
  required = false,
  messageError = "",
  value,
  setValue,
  disabled = false,
  id = "simple-input",
  watch,
  dependent = null,
}: CrudTextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMin8, setErrorMin8] = useState(false);
  const [errorUperCase, setErrorUperCase] = useState(false);
  const [errorLowerCase, setErrorLowerCase] = useState(false);
  const [errorNotNumber, setErrorNotNumber] = useState(false);
  const [errorCaracterEspecial, setErrorCaracterEspecial] = useState(false);
  const [errorSamePassword, setErrorSamePassword] = useState(true);
  const canSavePassword =
    dependent ? errorSamePassword : errorMin8 &&
    errorUperCase &&
    errorLowerCase &&
    errorNotNumber &&
    errorCaracterEspecial;

  const regexUperCase = /[A-Z]/;
  const regexLowerCase = /[a-z]/;
  const regexNotNumber = /[.\d]/;
  const regexEspecialCaracter = /[@$!%?&*#]/;

  const watchPassword = watch(name, "");
  const depentPassword = dependent ? watch(dependent, "") : null;

  useEffect(() => {
    if (!dependent) {
      setErrorMin8(watchPassword.length >= 8);
      setErrorUperCase(regexUperCase.test(watchPassword));
      setErrorLowerCase(regexLowerCase.test(watchPassword));
      setErrorNotNumber(regexNotNumber.test(watchPassword));
      setErrorCaracterEspecial(regexEspecialCaracter.test(watchPassword));
    } else {
        setErrorSamePassword(watchPassword === depentPassword);
    }
  }, [watchPassword, depentPassword]);

  useEffect(() => {
    if (value !== "" && value !== undefined && value !== null) {
      setValue(name, value);
    }
  }, [value]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Controller
      {...register(name, { required: required ? messageError : false })}
      name={name}
      control={control}
      defaultValue={value}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputField
            helperText={!canSavePassword && required && watchPassword.length ? messageError : null}
            error={!canSavePassword && required && watchPassword.length}
            {...field}
            label={label}
            required={required}
            name={name}
            disabled={disabled}
            id={id}
            type={showPassword ? "text" : "password"}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        );
      }}
    />
  );
}
