import {createBrowserRouter} from 'react-router-dom';
import { citizenRoutes } from '../features/Citizen/routes';
import TracksPage from '../pages/tracks/TracksPage';
import { tracksRoutes } from '../features/Tracks/routes';
import { licensedRoutes } from '../features/Licensed/routes';
import PrivateRoute from './PrivateRouter';
import NoPermissionPage from '../pages/access/NoPermissionPage';
import HomePage from '../pages/HomePage';
import { UsersRoutes } from '../features/Users/routes';
import { AccreditedRoutes } from '../features/Accredited/routes';

export const RouterApp = createBrowserRouter([
    { path: "/", element: <HomePage />},
    { path: "/tracks", element: <PrivateRoute Group={['cidadão']}><TracksPage /></PrivateRoute>},
    ...AccreditedRoutes,
    ...citizenRoutes,
    ...tracksRoutes,
    ...licensedRoutes,
    ...UsersRoutes,
    { path: "/acesso-restrito", element: <NoPermissionPage />},
]);
