import React from 'react';
import Button, { ButtonProps }  from '@mui/material/Button';
import { styled } from '@mui/material';

const BaseButton = styled(Button)<ButtonProps>(({ theme }) => ({
    fontSize: '0.875rem', 
    borderRadius: '8px', 
    p: '12px 24px', 
    textTransform: 'initial'
  }));    

export default BaseButton