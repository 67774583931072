import React from 'react';
import Grid2 from '@mui/material/Grid2';

import {styled, Theme, CSSObject} from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';

import UserAvatar from './UserAvatar';
import { useAuthetication } from '../../Contexts/AutheticationContext';
import Menu from './Menu';

const drawerWidthInit = 64;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidthInit * 4,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: "700ms",
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidthInit * 4,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideBarMenu() {
  const [drawerWidth, setDrawerWidth] = React.useState<number>(drawerWidthInit);
  const [mouseIsOver, setmouseIsOver] = React.useState<boolean>(false);
  
  const {isAuthenticated} = useAuthetication()


  const handleMenuClick = () => {
    closeSideBar();
  };

  const openSideBar = () => {
    if (!mouseIsOver) setDrawerWidth(drawerWidth * 4);

    setmouseIsOver(true);
  };

  const closeSideBar = () => {
    setDrawerWidth(drawerWidthInit);
    setmouseIsOver(false);
  };

 
  

  return (
    <Drawer
      PaperProps={{
        sx: {
          zIndex: 1300,
        },
      }}
      variant="permanent"
      open={mouseIsOver}
      onMouseOver={openSideBar}
      onMouseLeave={closeSideBar}
    >
      <Grid2 container sx={{ mt: 1, mb: 2 }} justifyContent="center"></Grid2>
      {isAuthenticated && (
        <>
          <UserAvatar showName={mouseIsOver} />
          <Menu hiddenMenuLabel={!mouseIsOver} onMenuClick={handleMenuClick}/>
        </>
      )}
    </Drawer>
  );
}
