import React from "react";
import Grid from "@mui/material/Grid2";
import LabelSumary from "./LabelSumary";
import Divider from '@mui/material/Divider';
import InfoSumary from "./InfoSumary";


export default function SumaryAccreditedGrid({label= '', info=''}) {
  return (
    <Grid container  sx={{ width: "100%" }}>
      <LabelSumary>
        {label}
      </LabelSumary>
      <InfoSumary>{ info}</InfoSumary>
      <Grid size={{xs:12}}>
      <Divider/>
      </Grid>
      </Grid>
  );
}
