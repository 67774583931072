import { Card, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Dayjs } from "dayjs";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import SchedulingCard from "../SchedulingCard";
import { State } from "@hookstate/core";
import { IMock } from "../StatusDialog";
import { useNavigate } from "react-router-dom";
import CancelButton from "../../../../components/Buttons/CancelButton";

interface ISchedulingTab {
  readonly date: State<Dayjs>;
  readonly locale: State<{ id: number; name: string } | null>;
  readonly value: State<number>;
  readonly mockData: Array<any>;
}

export default function SchedulingTab({
  date,
  locale,
  value,
  mockData,
}: ISchedulingTab) {
  const navigator = useNavigate()

  const handleClick = (mock: IMock) => {
    if (mock.status !== 'Cancelado' && mock.type_service === 'Instalação') {
      navigator("/scheduling/tracks-register", {state: {schedule: mock}});
    }
  }

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          padding: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6">
          {date.get().format("DD/MM/YYYY")} -{" "}
          {locale.get() ? locale.get()?.name : "Todos os locais"}
        </Typography>
      </Card>
      <Grid size={12} sx={{ height: '50vh' }}>
        <Typography paddingY={2}>
          Clique no agendamento para registrar o serviço.
        </Typography>
        <Card variant="outlined" sx={{ width: "100%", border: 'hidden', maxHeight: '80%', overflow: 'auto', paddingRight: 2, paddingBottom: 2}}>
          <Grid container spacing={2}>
            {mockData.map((mock): any => (
              <Grid size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
                <SchedulingCard
                  cpf={mock.cpf}
                  hour={mock.hour}
                  name={mock.name}
                  plate={mock.plate}
                  status={mock.status}
                  type_service={mock.type_service}
                  handleClick={() => handleClick(mock)}
                />
              </Grid>
            ))}
          </Grid>
        </Card>
      </Grid>
      <Grid
        container
        size={12}
        sx={{ gap: 1 }}
        display={"flex"}
        justifyContent="flex-end"
        marginTop={2}
      >
        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <CancelButton
            // label="Anterior"
            stepMode
            onCancelClick={() => value.set(value.get() - 1)}
          />
        </Grid>
      </Grid>
    </>
  );
}
