import { Button, SxProps, Theme } from "@mui/material";

interface CancelButtonProps {
  readonly onCancelClick: () => void;
  readonly stepMode?: boolean;
  readonly label?: string;
  readonly size?: "medium" | "small" | "large";
  readonly sx?: SxProps<Theme>;
  readonly variant?: "outlined" | "text" | "contained";
}

export default function CancelButton({
  onCancelClick,
  stepMode = false,
  label,
  size = "medium",
  sx,
  variant = 'outlined'
}: CancelButtonProps) {
  return (
    <Button
      variant={variant}
      onClick={onCancelClick}
      size={size}
      fullWidth
      sx={{
        borderRadius: "8px",
        padding: 1,
        textTransform: "capitalize",
        lineHeight: "normal",
        borderColor: "#C5CCD6",
        color: "#7A8B9A",
        margin: 1,
        ...sx
      }}
    >
      {label ? label : stepMode ? "Voltar" : "Cancelar"}
    </Button>
  );
}
