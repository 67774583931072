import { toast } from 'react-toastify';
// import notificationSound from './notification.mp3';
// import notificationSoundPriority2 from './notification.mp3';
// import notificationSoundPriority3 from './notification3.mp3';
// import alertOpen from './alertOpen.mp3';
import clickableNotification from '../../components/ClickableNotification';

interface INotify {
  readonly message: string
  readonly type: 'success' | 'warning' | 'error'
  readonly priority?: 1 | 2 | 3 | 4
  readonly clickableMessage?: {
    readonly id?: number | null
    readonly action?: any 
    readonly urlRedirection?: string
  } | null
  readonly autoCloseDuration?: false | number
}

// const sound = {
//   1: notificationSound,
//   2: notificationSoundPriority2,
//   3: alertOpen,
// }

export default function notify({message, type, priority = 1, clickableMessage, autoCloseDuration }:INotify){
  // const audio = new Audio(sound[priority]);
  // audio.loop = priority == 3 ? true : false;
  // audio.autoplay = true;

  return toast[type](clickableNotification({ message: message, clickableMessage: clickableMessage }), { 
    hideProgressBar: priority == 3 ? false : true, 
    autoClose: autoCloseDuration != undefined ? autoCloseDuration : priority == 3 ? false : 5000,
    // onOpen: () => audio.play(),
    //onClose: () => audio.pause(),
  });
}

