import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';


interface IClickableNotification {
  readonly message: string,
  readonly clickableMessage?: {
    readonly id?: number | null
    readonly action?: any
    readonly urlRedirection?: string
  } | null
}

export default function ClickableNotification({message, clickableMessage}:IClickableNotification){
  return (
    <>
    <Grid alignItems={"center"} justifyContent={'center'} container>
    {message}
    &nbsp;
    {/* {clickableMessage && (clickableMessage?.urlRedirection == window.location.pathname && window.location.pathname == '/dispatch' || clickableMessage?.urlRedirection == '/dispatch' && (window.location.pathname.includes('/window-support'))) 
    ? <Button color="primary" onClick={() => clickableMessage?.action(clickableMessage?.id)} variant='outlined' >{'Abrir'}</Button> 
    : clickableMessage && clickableMessage?.urlRedirection 
      ? <Grid alignItems={"center"} justifyContent={'center'} container> <Link to={`${clickableMessage?.urlRedirection}`}>{<Button color="primary" variant='outlined'>{'Abrir'}</Button>}</Link> </Grid>: <></>} */}
      </Grid>
    </>
  );
}