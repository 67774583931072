import { Container } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { ReactNode } from "react";
import SideBarMenu from "./Menu/SideBarMenu";
import MobileMenu from "./Menu/MobileMenu";
import {useAuth} from 'react-oidc-context';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

type LayoutProps = {
    readonly children: ReactNode;
}

export default function MainLayout({ children}: LayoutProps) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
        noSsr: true
    });

    const auth = useAuth();

    return (
        <> 
        {auth.isAuthenticated && (
            <>
                {isMobile?(
                    <MobileMenu />
                ):(
                    <SideBarMenu /> 
                )}
            </>
        )} 
        
            <Container maxWidth={false}  sx={{ 
                ml: isMobile? 0 :'64px' , 
                width: isMobile? '100%': 'calc(100% - 64px)',
                marginTop:   isMobile? '64px' :0 , 
            }}>
                    <ToastContainer />
                {children}
            </Container>
        </>
    )
}
