import Grid from '@mui/material/Grid2';
import MainLayout from '../../core/Layout/MainLayout';
import CardTracks from '../../features/Tracks/components/Card/CardTracks';
import ContainerLayout from '../../core/Layout/ContainerLayout';
import TitleLayout from '../../core/Layout/TitleLayout';
import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import {useAuth} from 'react-oidc-context';
import { listTracker } from '../../features/Tracks/services';


export default function TracksPage() {
  
  const [idUser, setIdUser] = React.useState<any>(null);
  const [tracks, setTracks] = React.useState<Array<any>>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const auth = useAuth();

  const getAllTrack = async () => {
    const response = await listTracker(idUser)
    if(!response.hasError){
      const listTrack = response.response?.data;
      setTracks(listTrack)
      setIsLoading(false)
    }
    else{
      setIsLoading(false)
      console.log('Erro listagem track')
    }
  } 

  useEffect( ()=> {
    if(idUser !== null && idUser !== undefined){
      getAllTrack()
    }
  }, [idUser])

  useEffect(()=>{
    setIsLoading(true)
    if(auth.isAuthenticated){
      const ideUserKC = auth.user?.profile.sub as any;
      if(ideUserKC !== undefined){
        setIdUser(ideUserKC)
      }else{
        setIsLoading(false)
      }
    }else{
      setIsLoading(false)

    }
 }, [auth.isAuthenticated])

  return (
    <MainLayout>
      <TitleLayout>
        Lista de Rastreadores
      </TitleLayout>
      <ContainerLayout loading={isLoading}>
        {tracks.length > 0? 
        (
          <Grid container spacing={3}>
            {tracks.map((track):any =>
              <Grid size={{xs: 12, sm:6, md: 6, lg: 4}}>
                <CardTracks 
                  trackCode={track?.trackCode} 
                  plate={track?.plate} 
                  status={track?.status} 
                  keyword={track?.keyword} 
                  trackday={track?.recentUpdate}
                  />
              </Grid>
            )}
          </Grid>
        ):(
          <Grid container alignItems={"center"} justifyContent={"center"} sx={{height: '80vh'}}>
            <Typography align="center" sx={{ color: '#a63939', mb: 1 , fontSize: '1rem'}}>
              Você ainda não tem nenhum rastreador cadastrado. Vá até o posto de uma credenciada com seu veículo e o equipamento, para realizar o cadastro.
            </Typography>
          </Grid>
        )}
      </ContainerLayout>
      
    </MainLayout>
 
 
  );
}
