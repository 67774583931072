import React from 'react';

import BaseButton from './BaseButton';

type ButtonProps = {
    readonly children: React.ReactNode, 
    type?: "button" | "submit" | "reset", 
    onClick?: ()=> void,
    disabled?: boolean,
    fullWidth?:boolean

}

export default function PrimaryButton({
    type="button", 
    onClick =()=>{} , 
    disabled= false,  
    children,
    fullWidth=false
}: ButtonProps) {    
    return (
        <BaseButton  fullWidth={fullWidth} size="small"  variant="contained" type={type} onClick={onClick} disabled={disabled}>
          {children}
        </BaseButton>
          
    )
}