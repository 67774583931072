import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import InputField from '../../Field';
import { getCitizen } from './services';
import { ICitizen } from '../../../features/Tracks/components/SearchCitizenTab';

interface CitizenProps {
    readonly onCitizenChange: (value: ICitizen) => void;
    readonly citizenId?: string;
    readonly disabled?: boolean
    readonly required?: boolean
    readonly error?: boolean
}

export default function FilterAutocompleteCitizen({ onCitizenChange, citizenId, required = false, error = false }: CitizenProps) {
    const [options, setOptions] = useState<readonly ICitizen[]>([]);
    const [value, setValue] = useState<any>();

    useEffect(() => {
        getCitizen().then(citizen => {
            const citizenFiltered = citizen.filter(item => !!item.cpf)
            setOptions(citizenFiltered)
            setValue(null)
        })
    }, [])

    useEffect(() => {
        if (!citizenId) {
            setValue(null)
        } else {
            setValue(options.find(opt => opt.id === citizenId) ?? null)
        }
    }, [citizenId])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                id='filter-citizen'
                fullWidth
                options={options}
                value={value}
                noOptionsText={'Sem opção'}
                size='small'
                isOptionEqualToValue={(option, value) => option.id === value}
                getOptionLabel={(option) => option.cpf}
                onChange={(_, citizen) => {
                    setValue(citizen)
                    onCitizenChange(citizen)
                }}
                renderInput={(params) => (
                    <InputField
                        {...params}
                        label={'CPF'}
                        required={required}
                        error={error}
                        helperText={required || error ? 'Campo obrigatório.' : ''}
                    />
                )}
            />
        </FormControl>
    )
}
