import { Tab, Tabs, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import MainLayout from "../../../../core/Layout/MainLayout";
import TitleLayout from "../../../../core/Layout/TitleLayout";
import GridForm from "../../../../components/Form/components/GridForm";
import { useHookstate } from "@hookstate/core";
import dayjs, { Dayjs } from "dayjs";
import TabPanel from "../../../../components/TabPanel";
import FiltersSchedulingTab from "../../components/FiltersSchedulingTab";
import SchedulingTab from "../../components/SchedulingTab";
import ContainerLayout from "../../../../core/Layout/ContainerLayout";

export default function ListScheduling() {
  const date = useHookstate<Dayjs>(dayjs());
  const value = useHookstate(0);
  const locale = useHookstate<{ id: number; name: string } | null>(null);

  const mockData = [
    {
      hour: "08:00",
      type_service: "Instalação",
      name: "Helen Freitas",
      cpf: "86174446573",
      plate: "AAA2222",
      status: "Não realizado",
    },
    {
      hour: "09:00",
      type_service: "Reparo",
      name: "Felipe Neves",
      cpf: "86174446573",
      plate: "AAA1111",
      status: "Realizado",
    },
    {
      hour: "10:00",
      type_service: "Desinstalação",
      name: "Fernanda Fidalgo",
      cpf: "86174446573",
      plate: "AAA1111",
      status: "Cancelado",
    },
    {
      hour: "11:00",
      type_service: "Instalação",
      name: "Adhemar Fontes",
      cpf: "86174446573",
      plate: "AAA1111",
      status: "Em andamento",
    },
    {
      hour: "12:00",
      type_service: "Instalação",
      name: "Maria Alves Freitas",
      cpf: "86174446573",
      plate: "AAA1111",
      status: "Agendado",
    },
  ];

  function tabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <MainLayout>
      <TitleLayout>Agendamentos</TitleLayout>
      <ContainerLayout minHeight="50%">
        <Tabs
          value={value.get()}
          aria-label="basic tabs example"
          onChange={(event: React.SyntheticEvent, newTab) => value.set(newTab)}
        >
          <Tab label="Dia e local" {...tabProps(0)} />
          <Tab label="Agenda" {...tabProps(1)} />
        </Tabs>
        <TabPanel value={value.get()} index={0}>
          <FiltersSchedulingTab date={date} locale={locale} value={value} />
        </TabPanel>
        <TabPanel value={value.get()} index={1}>
          <SchedulingTab
            date={date}
            locale={locale}
            mockData={mockData}
            value={value}
          />
        </TabPanel>
      </ContainerLayout>
    </MainLayout>
  );
}
