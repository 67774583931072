import React, { useState } from "react";
import { TextField } from "@mui/material";
import ConfirmButton from "../../Buttons/ConfirmButton";
import CancelButton from "../../Buttons/CancelButton";
import Grid from "@mui/material/Grid2";

type SearchFieldProps = {
  label: string;
  onTextIsSending: (value: string) => void;
  onClearSearch: () => void;
};

export default function SearchField({
  label = "",
  onTextIsSending,
  onClearSearch,
}: SearchFieldProps) {
  const [value, setValue] = useState<string>("");

  const sendText = () => {
    onTextIsSending(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      sendText();
    }
  };

  const cleanSearch = () => {
    setValue("");
    onClearSearch();
  };

  return (
    <Grid container wrap="nowrap" sx={{ paddingBottom: 2 }}>
      <TextField
        id="search-field"
        label={label}
        value={value}
        size="small"
        sx={{
          width: "60%",
          marginTop: 1,
          marginRight: 1,
          borderRadius: "50%",
          "& .MuiInputBase-root": {
            border: "0.2px",
            borderRadius: "10px",
            "& input": {
              padding: 1,
            },
          }
        }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setValue(event.target.value);
        }}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
          handleKeyDown(event);
        }}
      />

        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end", height: '100%'}}
        >
          <CancelButton onCancelClick={cleanSearch} label="Limpar" sx={{padding: 1.25}}/>
        </Grid>
        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <ConfirmButton onConfirmClick={sendText} label="Buscar" />
        </Grid>
    </Grid>
  );
}
