import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import { Container, Typography } from "@mui/material";
import MainLayout from "../../../core/Layout/MainLayout";
import { useNavigate } from "react-router-dom";
import FormCrud from "../../../components/Form/components/FormCrud";
import GridForm from "../../../components/Form/components/GridForm";
import { createCitizen } from "../services";
import { useHookstate } from "@hookstate/core";
import ConfirmDialog from "../../../components/ConfirmDialog";
import { getUser } from "../../../services/fetch";
import notify from "../../../utils/notify";
import ContainerLayout from "../../../core/Layout/ContainerLayout";
import TitleLayout from "../../../core/Layout/TitleLayout";

function CitizenRegisterPage() {
  const navigate = useNavigate();
  const user = getUser();

  const [messageError, setMessageError] = useState<any>("");
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [fields, setFields] = useState<Array<any>>([
    {
      name: "full_name",
      type: "string",
      label: "Nome Completo",
      messageError: "O nome é obrigatório",
      required: true,
      value: "",
      id: "full_name_input",
    },
    {
      name: "cpf",
      type: "string",
      label: "CPF",
      required: true,
      messageError: "O CPF é obrigatório",
      value: "",
      id: "cpf_input",
      mask: "999.999.999-99",
    },
    {
      name: "phone",
      type: "string",
      label: "Telefone",
      required: true,
      messageError: "O telefone é obrigatório",
      value: "",
      id: "phone_input",
      mask: "(99) 99999-9999",
    },
    {
      name: "email",
      type: "string",
      label: "Email",
      required: true,
      value: "",
      id: "email_input",
    },
    {
      name: "birth_date",
      type: "date",
      label: "Data de Nascimento",
      model: "Nascimento",
      messageError: "A Data de nascimento é obrigatório",
      required: true,
      value: "",
      id: "birth_date_input",
    },
    // { name: 'password', type: 'password', label: 'Senha', messageError: 'A senha deve ter, no mínimo 8 caracteres, letra maiúscula e minúscula, números e caracteres especiais.', required: true, value: '', id: 'password_input', inputType: 'password'  },
    // { name: 'repassword', type: 'password', label: 'Confirme a senha', messageError: 'As senhas não combinam.', required: true, value: '', id: 'repassword_input', inputType: 'password', dependent: 'password' },
  ]);
  const openConfirmDialog = useHookstate(false);
  const temporaryPassword = useHookstate("");

  const submitForm = async (body: any) => {
    setError(false);
    setMessageError("");
    setProcessing(true);

    const { hasError, data, error } = await createCitizen(body);

    if (!hasError) {
      temporaryPassword.set(data.user.credentials[0].value);
      openConfirmDialog.set(true);
      notify({ message: `Usuário cadastrado com sucesso`, type: "success" });
    } else {
      setError(true);
      setMessageError(error);

      if (error?.invalid_params && error.invalid_params.length) {
        error.invalid_params?.map((item: { reason: string }) =>
          notify({
            message: `${item.reason}`,
            type: "error",
          })
        );
      } else if (error?.details.length) {
        notify({
          message: `${error.details}`,
          type: "error",
        });
      } else {
        notify({
          message: `Algo deu errado, tente novamento mais tarde.`,
          type: "error",
        });
      }
    }

    setProcessing(false);
  };

  const handleAgree = () => {
    user ? navigate("/tracks-register") : navigate("/");
    openConfirmDialog.set(false);
  };

  const messageCitizenConfirmation = (
    <Typography>
      Para fazer login, utilizar o <b>CPF</b> e a senha temporária:
      <b>{temporaryPassword.get()}</b>
    </Typography>
  );

  const messageOperatorConfirmation = (
    <Typography>
      Para fazer login, o cidadão deve utilizar o <b>CPF</b> e a{" "}
      <b>senha temporária enviada por email</b>.
    </Typography>
  );

  return (
    <MainLayout>
      <TitleLayout>Cadastro do Cidadão</TitleLayout>

      <ContainerLayout minHeight="50%">
        <FormCrud
          fields={fields}
          onSubmitForm={submitForm}
          messagesError={messageError}
          error={error}
          onCancelClick={() => navigate("/")}
          onConfirmClick={() => {}}
          column={4}
          showTermOfUse
        />
      </ContainerLayout>
      <ConfirmDialog
        handleAgree={handleAgree}
        open={openConfirmDialog}
        title="Cadastro realizado com sucesso"
        moreInformation={
          user ? messageOperatorConfirmation : messageCitizenConfirmation
        }
      />
    </MainLayout>
  );
}

export default CitizenRegisterPage;
