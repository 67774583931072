import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import parse from 'html-react-parser';


export default function SimpleDialogFeedback({
    open = false, 
    onCloseDialog = ()=> {}, 
    onConfirm = ()=> {},
    title="",
    content= '',
}) {

  return (
      <Dialog
        open={open}
        onClose={onCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle >
         {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
           {parse(content)}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{justifyContent:'center'}}>
    
          <Button onClick={onConfirm} autoFocus color="primary" variant="contained" >
            ok
          </Button>
        </DialogActions>
      </Dialog>

  );
}