import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuthetication } from './Contexts/AutheticationContext';


export default function PrivateRoute({ children, Group = []}) {
  const {isAuthenticated, userGroup, checkGroup} = useAuthetication()
  const [isAuthorized, setIsAuthorized] = useState(false);

  const navigate = useNavigate();

  const check = async () =>{
    const canAccess = await checkGroup(Group)
    //console.log(Group, canAccess)
    if(!canAccess){
      navigate("/acesso-restrito");
    }
    setIsAuthorized(canAccess)
  }

    useEffect(() => {
    if (isAuthenticated && userGroup.length > 0) {
      check()
    } 
   }, [userGroup]);



    return (
    
        <>{
          isAuthorized?
            children: ''
          }
        </>
    )

}