import React, { useEffect, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { useAccreditedStep } from '../../../contexts/AccreditedStepContext';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import BaseButton from '../../../../../components/Buttons/BaseButton';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';
import SecondaryButton from '../../../../../components/Buttons/SecondaryButton';

export default function WebControlStepForm({onFinalize = ()=>{}, canMoveForward = true}) {
  const {handleNext, handleBack, activeStep, isLastStep, lengthStep} = useAccreditedStep();
  const [canMove, setCanMove] = React.useState<boolean>(true);
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
    noSsr: true
});


  const handleBackStep = () => {
    handleBack()
  }; 

  useEffect(()=>{
    setCanMove(canMoveForward)
    //setCanMove(true)
  }, [canMoveForward])

  return (
      <>
        <SecondaryButton
          disabled={activeStep === 0}
          onClick={handleBackStep}
        >

          <KeyboardArrowLeft />
          Voltar
        </SecondaryButton>

        {isLastStep? (
          <PrimaryButton 
          disabled={!canMove}

          onClick={onFinalize}
          >
          Finalizar
          <KeyboardArrowRight />
        </PrimaryButton>
        ):(
          <PrimaryButton 
          type="submit" 
          disabled={!canMove}
          
          >
          Avançar
          <KeyboardArrowRight />
        </PrimaryButton>
        )}
        
</>          
  );
}
