import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import { State, useHookstate } from "@hookstate/core";
import InputField from "../../../../components/Field";
import CancelButton from "../../../../components/Buttons/CancelButton";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import InputMask from "react-input-mask";
import { removePlateMask } from "../../../../utils/stringsManipulations";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";

interface ITracksForm {
  readonly value: State<number>;
  readonly trackId: State<string>;
  readonly plate: State<string>;
  readonly brand: State<string>;
  readonly trackChip: State<string>;
  readonly state?: {
    readonly cpf: string;
  };
}

export default function TrackFormTab({
  value,
  trackId,
  plate,
  brand,
  state,
  trackChip
}: ITracksForm) {
  const trackError = useHookstate(false);
  const trackChipError = useHookstate(false);
  const plateError = useHookstate(false);
  const [error, setError] = useState(false);
  const navigator = useNavigate();

  const handleConfirmClick = async () => {
    if (!trackId.get().length) {
      trackError.set(true);
    }

    if (!trackChip.get().length) {
      trackChipError.set(true);
    }

    if (plate.get().length < 7) {
      plateError.set(true);
    }

    if (!trackError.get() && !plateError.get() && !trackChipError.get()) {
      value.set(value.get() + 1);
    }
  };

  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      {!!state && (
        <Grid size={12}>
          {" "}
          <Typography>
            {" "}
            <b>Placa:</b> {plate.get()}{" "}
          </Typography>
        </Grid>
      )}
      
      {!state && (
        <Grid size={{ xs: 12, sm: 6, md: 3 }}>
          <InputMask
            mask={`aaa9*99`}
            value={plate.get()}
            onChange={(event: { target: { value: string } }) => {
              plate.set(removePlateMask(event.target.value));
              plate.get().length == 7 && plateError.set(false);
            }}
            disabled={!!state}
          >
            <InputField
              helperText={
                plateError.get() && "A placa do veículo é obrigatória"
              }
              error={plateError.get()}
              label="Placa do Veículo"
              required
            />
          </InputMask>
        </Grid>
      )}
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          label="Código do Rastreador"
          error={trackError.get()}
          helperText={trackError.get() && "O ID do rastreador é obrigatório"}
          value={trackId.get()}
          onChange={(event: { target: { value: string } }) => {
            trackId.set(event.target.value);
            trackId.get().length && trackError.set(false);
          }}
          required
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          label="Chip do Rastreador"
          error={trackChipError.get()}
          helperText={trackChipError.get() && "O chip do rastreador é obrigatório"}
          value={trackChip.get()}
          onChange={(event: { target: { value: string } }) => {
            trackChip.set(event.target.value);
            trackChip.get().length && trackChipError.set(false);
          }}
          required
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField
          label="Marca do Rastreador"
          value={brand.get()}
          onChange={(event: { target: { value: string } }) => {
            brand.set(event.target.value);
          }}
        />
      </Grid>
      <Grid
        container
        size={12}
        sx={{ gap: 1 }}
        display={"flex"}
        justifyContent="flex-end"
        paddingTop={2}
      >
        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <CancelButton
            stepMode
            onCancelClick={() =>
              state ? navigator(-1) : value.set(value.get() - 1)
            }
          />
        </Grid>

        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <ConfirmButton stepMode onConfirmClick={handleConfirmClick} />
        </Grid>
      </Grid>
    </Grid>
  );
}
