import React from 'react';
import Button  from '@mui/material/Button';
import { Link } from 'react-router-dom';

type ButtonProps = {
    readonly children: React.ReactNode, 
    to: string, 
    id?: string
}

export default function CreateButton({to, children, id="btn-create"}: ButtonProps) {    
    return (
        <Button
            variant="contained"
            to={to}
            component={Link}
            id={id}
            sx={{fontSize: '0.875rem', borderRadius: '8px', p: '12px 24px', textTransform: 'initial'}}>
            {children}
        </Button>
    )
}