import React, { useState, useEffect } from 'react';

import { IconButton, ListItemButton, ListItemText, Menu, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid2';
import Typography  from '@mui/material/Typography';
import {useAuth} from 'react-oidc-context';
import { useNavigate } from "react-router-dom";
import { getFirtNameUserKeyCloak } from '../../Users/UsersCore';

export default function UserAvatar({showName= true}) {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [src, setSrc] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');

  const open = Boolean(anchorEl);
  const id = open ? 'menu-appbar' : '';
  const auth = useAuth();


  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleLogout = async (event: React.MouseEvent<HTMLElement>) => {
    var host = window.location.protocol + "//" + window.location.host;

    console.log(host, 'handleLogout') 
    auth.signoutRedirect({post_logout_redirect_uri: host})

  };

  const getFirtname = async () => {
     setFirstName(await getFirtNameUserKeyCloak() as any)
  } 

  useEffect( () => {
   
    if(auth.isAuthenticated){
      getFirtname() 
    }
  }, [auth.isAuthenticated])



  return (
    <>
     
      <Grid container wrap='nowrap'> 
        <Tooltip title={'Sair'} placement="bottom">
          <IconButton
            onClick={handleMenu}
          >
            {/* <Badge color={!statusSocket? 'error': 'primary'} overlap="circular" variant="dot"> */}
            <Avatar
                alt="Usuario do sistema"
                src={src}
                sx={{ width: 48, height: 48 }}
                id="avatar-user"
              />
            {/* </Badge> */}
          </IconButton>
          
        </Tooltip>
        {showName && (
          <Typography 
            variant='body2' 
            component="p" 
            sx={{
                color: '#7A8B9A', 
                fontWeight: 600, 
                display: 'flex', 
                alignItems: 'center'
                }}  id="name-user-logado">
            {firstName}
            </Typography> 
        )}
      
      </Grid>

      <Menu
        id={id}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
        sx={{
          marginTop: '30px',
          zIndex: 9999
        }}
      >
        <ListItemButton onClick={()=>{ navigate("/mudar-senha")}}>
          <ListItemText primary={'Alterar Senha'} />
        </ListItemButton>
        <ListItemButton onClick={handleLogout}>
          <ListItemText primary={'Sair'} />
        </ListItemButton>
      </Menu>
    </>
  )
}