import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import FilterAutocompleteCitizen from "../../../../components/Filters/FilterAutocompleteCitizen";
import CancelButton from "../../../../components/Buttons/CancelButton";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import InputField from "../../../../components/Field";
import { State } from "@hookstate/core";
import { useNavigate } from "react-router-dom";

export interface ICitizen {
  readonly id: string;
  readonly name: string;
  readonly cpf: string;
  readonly phone: string;
  readonly birthDate: string;
}
interface ISearchCitizenTab {
  readonly citizen: State<string | null>;
  readonly value: State<number>;
  readonly citizenDetails: State<ICitizen | null>;
}

export default function SearchCitizenTab({
  citizen,
  value,
  citizenDetails,
}: ISearchCitizenTab) {
  const [error, setError] = useState(false);

  const handleConfirmClick = () => {
    if (citizen.get()) {
      value.set(value.get() + 1);
    } else {
      setError(true);
    }
  };

  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <FilterAutocompleteCitizen
          citizenId={citizen.get() ?? undefined}
          onCitizenChange={(value) => {
            citizen.set(value?.id ?? null);
            citizenDetails.set(value)
            value !== null && setError(false);
          }}
          error={error}
          required
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }}>
        <InputField label={citizenDetails.get() ? citizenDetails.get()?.name : "Nome"} disabled />
      </Grid>
      <Grid
        container
        size={12}
        sx={{ gap: 1 }}
        display={"flex"}
        justifyContent="flex-end"
        paddingTop={2}
      >
        {/* <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <CancelButton onCancelClick={() => {}} />
        </Grid> */}

        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <ConfirmButton stepMode onConfirmClick={handleConfirmClick} />
        </Grid>
      </Grid>
    </Grid>
  );
}
