import React, {  useState } from 'react';
import Grid from '@mui/material/Grid2';

import { useForm } from 'react-hook-form';
import SimpleInputFieldForm from '../../../../components/Form/components/Fields/SimpleInputFieldForm';
import CNPJFieldForm from '../../../../components/Form/components/Fields/CNPJFieldForm';
import { useAccreditedStep } from '../../contexts/AccreditedStepContext';
import ControlStepForm from './Steps/ControlStepForm';
import { useAccreditedForm } from '../../contexts/AccreditedFormContext';


export default function CompanyDataAccreditedForm() {
  const {handleNext} = useAccreditedStep();
  const {mergeFieldsInState, fields} = useAccreditedForm()

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: {  isValid },
    
  } = useForm({ mode: "all" });

  const submitForm = async (event:any) => {
    try {
      
     if (isValid){
       await mergeFieldsInState(event)
       handleNext()
     }

    } catch (e) {
      console.log(e, 'submit')
    }
   }




  return (
    <Grid container component="form" sx={{padding: 2, width: '100%'}} spacing={2} onSubmit={handleSubmit(submitForm)}>
      <Grid size={{xs:12, md:6}}>
        <CNPJFieldForm 
          name='cnpj' 
          label='CNPJ' 
          required={true}  
          value= {fields?.cnpj} 
          id='cnpj_input'
          register={register} 
          control={control}
          setValue={setValue}

        />
      </Grid>
               
    <Grid size={{xs:12, md:6}}>
      <SimpleInputFieldForm 
        name='corporate_name' 
        label='Razão Social' 
        required={true}  
        messageError='A Razão Social é obrigatório' 
        value= {fields?.corporate_name} 
        id='trade_name'
        register={register} 
        control={control}
        setValue={setValue}
      />
    </Grid>
    <Grid size={{xs:12, md:6}}>
      <SimpleInputFieldForm 
        name='trade_name' 
        label='Nome Fantasia' 
        required={true}  
        messageError='Nome Fantasia é obrigatório' 
        value= {fields?.trade_name} 
        id='nome_fantasia'
        register={register} 
        control={control}
        setValue={setValue}
      />
    </Grid>
   
    
      <ControlStepForm canMoveForward={isValid} />
    
    </Grid>
           

  );
}

