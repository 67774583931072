import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { useNavigate } from 'react-router-dom';
import Stepper from '@mui/material/Stepper';

import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CompanyDataAccreditedForm from './CompanyDataAccreditedForm';
import { useAccreditedStep } from '../../contexts/AccreditedStepContext';
import AddressAccreditedForm from './AddressAccreditedForm';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import SumaryAccreditedForm from './Confirmation/SumaryAccreditedForm';
import AdminDataAccreditedForm from './AdminDataAccreditedForm';


function CreateAccreditedForm() {
  const steps = [
    {label: 'Dados Empresa'},
    {label: 'Endereço'},
    {label: 'Dados Administrador'},
    {label: 'Confirmação'},
  ]
  const {activeStep, setSteps, navigateStep} = useAccreditedStep();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
      noSsr: true
  });
  
  useEffect(()=>{
    setSteps(steps)
  }, [])

  return (
   <>
    <Stepper 
      activeStep={activeStep} 
      sx={{
        borderBottom: 'solid 1px', 
        pb:2,
        borderColor: `${theme.palette.primary.main}`,
        width: '100%'
      }}
    >
     
      {!isMobile && (
        steps.map( (step, index)=>
          <Step key={index}>
            <StepLabel onClick={()=>navigateStep(index)}>{step.label}</StepLabel>
            
            </Step>
          )      
        )} 
        {isMobile  &&(
          <Grid container justifyContent={"center"} sx={{width: '100%'}}>
            <StepLabel><b>{steps[activeStep].label}</b></StepLabel>
          </Grid>
        )} 
    </Stepper>
    <Grid container sx={{ pt: 1}}>
            
        {activeStep === 0 && (
          <React.Fragment>
            <CompanyDataAccreditedForm /> 
          </React.Fragment>
        ) }

        {activeStep === 1 && ( 
          <React.Fragment>
            <AddressAccreditedForm />
          </React.Fragment>
        )} 
        {activeStep === 2 && ( 
          <React.Fragment>
            <AdminDataAccreditedForm />
          </React.Fragment>
        )} 
          {activeStep === 3 && (
            <React.Fragment>
              <SumaryAccreditedForm />
            </React.Fragment>
        ) }
            
    </Grid> 
           
           </>

  );
}

export default CreateAccreditedForm;
