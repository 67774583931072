import Typography  from "@mui/material/Typography";
import Grid  from "@mui/material/Grid2";

import React, { ReactNode } from "react";

type LayoutProps = {
    readonly children: ReactNode;
}

export default function TitleLayout({ children}: LayoutProps) {
    return (
        <Grid container >
            <Typography component='h1' sx={{fontSize: '1.5rem', fontWeight: 700, margin: 0, marginBottom:2,  marginTop:2}}>
                {children}
            </Typography>
        </Grid>    
    )
}
