import React, { ReactElement } from 'react'
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';


interface ListItemSideBarProps {
  readonly text: string,
  readonly icon?: ReactElement<any, any>,
  readonly showText?: boolean,
  readonly link: string,
  readonly activeMenu?: boolean,
  readonly handleClick: () => void
}

export default function ListLinkSideBar({text, icon, showText = true, link, activeMenu, handleClick}:ListItemSideBarProps){
  return (
    <Link to={link} style={{textDecoration: 'none', color: '#121212'}}>
          <ListItemButton 
            onClick={handleClick} 
            sx={{ 
                borderLeft:  activeMenu && !showText? '6px solid': 'none', 
                borderLeftColor:  'primary.main',
                bgcolor:  activeMenu && showText? 'primary.light': '',
                "&:hover": {
                  backgroundColor: "primary.main",
                  color: "white",
                  "& .MuiListItemIcon-root": {
                    color: "white"
                  }
                },
                marginTop: '16px', marginBottom: '16px' ,

            }} 
            >
            <ListItemIcon>
              {icon}
            </ListItemIcon>
            {showText && (
                <ListItemText primary={text} sx={{textDecoration: 'none'}}/>
            )}
          </ListItemButton>
    </Link>
  )
}