import React, { useState } from "react";
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import { Button, CardActionArea, CardActions } from "@mui/material";
import Chip from '@mui/material/Chip';

type CardAccreditedProps = {
    nome: string;
    cnpj: string;

}

export default function CardAccredited({ 
    nome= '', 
    cnpj = '', 
   
}: CardAccreditedProps) {


    return (
        <Card elevation={0} sx={{border: '2px solid #eaeaea', borderRadius:'8px'}}> 
         <CardActionArea>
            <CardContent sx={{pb: 1 , position: 'relative', backgroundColor:'#efefef'}}>
               
                <Grid container direction={'column'}>
                    <Typography component="p" gutterBottom sx={{ color: 'text.secondary', fontSize: 16, display: 'block' }}>
                        <b>Credenciada: </b>{nome}
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', mb: 1, fontSize: '1rem'  }}><b>CNPJ: </b>{cnpj}</Typography>
                   
                </Grid>
               
            </CardContent>
        
            </CardActionArea>                   
        </Card>
    )
}
