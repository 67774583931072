import { ICitizen } from "../../../../features/Tracks/components/SearchCitizenTab";
import { authFetch } from "../../../../services/fetch";

export async function getCitizen(): Promise<readonly ICitizen[]> {
    try {
      const { data } = await authFetch({
        url: '/users',
        method: 'GET',
      })
      return data;
    } catch (error) {
      throw error;
    }
  }