import React, { useEffect, useState } from 'react';
import { UseFormGetValues, UseFormRegister, UseFormSetValue, Controller} from 'react-hook-form';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface CrudTextFieldProps {
  readonly register: UseFormRegister<any>;
  readonly name: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly control?: any;
  readonly label?: string;
  required: boolean;
  messageError?: string;
  value: string;
  readonly setValue: UseFormSetValue<any>,
  id?: string

}

export default function DateField({ label, register, control = {}, name, required = false, messageError = "", value, setValue, id="date-form" }: CrudTextFieldProps) {
  
  useEffect(()=> {
    if (value !== '' && value !== undefined && value !== null) {
      setValue(name, dayjs(value))
    }
  }, [value])

  const isValidDate = (date: string) => {
    if(required){
      return dayjs(date, 'YYYY-MM-DD').isValid() || "A data é inválida";
    }
    return true
  }
    
  

  return (
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'pt-br'}>
        <Controller
           {...register(
            name,
            {
              required: { 
                value: required,
                message: messageError === '' ? 'A data é inválida' : messageError,
              },
             
            }
          )}
          rules={{ validate: { isValidDate } }}
            name={name}
            control={control}
            render={({
              field,
              fieldState: { error },
            }) => {

              return (
                <DatePicker 
                  label={label}
                  {...field}
                  slotProps={{
                    textField: {
                      helperText: error && required? error.message : '',
                      error: required?!!error: false,
                      required: required,
                      id:id,
                      size:"small",
                      fullWidth: true,
                      sx:{
                        mt: 0, 
                        borderRadius: '50%',
                        '& .MuiInputBase-root' : {
                        border: '0.2px',
                        borderRadius: '10px',
                        '& input':{
                          padding: 1
                        }
                      } 
                      }
                    },
                  }}
                  onChange={(value: string | dayjs.Dayjs | null | undefined) => {
          
                    // const currentDate = value && dayjs(value, 'YYYY-MM-DD').isValid() ? dayjs(value, 'YYYY-MM-DD').format('YYYY-MM-DD') : value;

                    field.onChange(value);
                  }}

              />
              )
            }}
          />
      </LocalizationProvider>
  )
}