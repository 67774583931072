import { Button, SxProps, Theme } from "@mui/material";

interface ConfirmButtonProps {
  readonly stepMode?: boolean;
  readonly onConfirmClick?: () => void;
  readonly type?: "button" | "submit" | "reset";
  readonly label?: string;
  readonly size?: "medium" | "small" | "large";
  readonly sx?: SxProps<Theme>;
  readonly colorButton?: "primary" | "inherit" | "secondary" | "success" | "error" | "info" | "warning";
}

export default function ConfirmButton({
  stepMode = false,
  onConfirmClick,
  type = "button",
  label,
  size = 'medium',
  sx,
  colorButton = 'primary'
}: ConfirmButtonProps) {
  return (
    <Button
      type={type}
      onClick={onConfirmClick}
      variant="contained"
      size={size}
      fullWidth
      color={colorButton}
      sx={{
        borderRadius: "8px",
        padding: 1,
        textTransform: "initial",
        lineHeight: "normal",
        margin: 1,
        ...sx
      }}
    >
      {label ? label : stepMode ? "Próximo" : "Confirmar"}
    </Button>
  );
}
