import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import {UseFormGetValues, UseFormRegister, Controller, UseFormSetValue} from 'react-hook-form';
import InputMask from 'react-input-mask';
import InputField from '../../../Field';

interface CrudTextFieldProps {
  readonly register: UseFormRegister<any>;
  readonly name: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly control?: any;
  readonly label?: string;
  required?: boolean;
  messageError?: string;
  value?: string;
  readonly setValue: UseFormSetValue<any>,
  disabled?: boolean
  id?: string
  onBlurCEP?: (any: any)=> void

}

export default function CEPFieldForm({ 
  label="CEP", 
  register, 
  control = {}, 
  name, 
  required = false, 
  messageError = "", 
  value ='', 
  setValue,
  disabled = false, 
  id = 'cpf_form',
  onBlurCEP = () =>{} 
}: CrudTextFieldProps) {

  useEffect(()=> {
    if (value !== '' && value !== undefined && value !== null) {
      setValue(name, value)
    }
  }, [value])

 return (
    <Controller
    defaultValue={value}
    {...register(
      name,
      {
        required: { 
          value: required,
          message: messageError === '' ? 'CEP é obrigatório' : messageError,
        },
        pattern: {
          value: /^\d{2}\d{3}[-]\d{3}$/i,
          message: 'CEP inválido',
        },
      }
    )}
     name={name}
     control={control}
     render={({
      field,
      fieldState: { error },
    }) => {
      return (
       <InputMask
        mask={"99999-999"}
        {...field}
        maskChar="_"
        disabled={disabled}
        onBlur={(e)=>onBlurCEP(e.target.value)}
        >
        <InputField
          helperText={error && required? error.message : null}
          error={required?!!error: false}
          label={label}
          name={name}
          id={id}
        />
       </InputMask>
      );
     }}
    />
 );
}
