import { authFetch } from "../../../services/fetch";

export interface IError {
    readonly type: string;
    readonly title: string;
    readonly details: string;
    readonly status: number;
    readonly instance: string;
    readonly invalid_params: {
        readonly name: string;
        readonly reason: string;
    }[]
}

export async function createAccredited(data: any) {
    try {
        const response = await authFetch({
            url: '/accredited-company/create',
            data: data
        })
        return {hasError: false, data: response.data}
    } catch (error) {
        console.error('Create Accredited: ', error);
        return {hasError: true, error: error as IError}
    }
}