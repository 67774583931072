import React, { useCallback } from "react";
import List from "@mui/material/List";

import ListLinkSideBar from "./ListLinkSideBar";
import ShareLocationOutlinedIcon from "@mui/icons-material/ShareLocationOutlined";
import PersonAddAlt1OutlinedIcon from "@mui/icons-material/PersonAddAlt1Outlined";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { useAuthetication } from "../../Contexts/AutheticationContext";


export default function Menu({hiddenMenuLabel=false, onMenuClick = ()=>{}}) {
  const {userGroup} = useAuthetication()
  const [activeMenu, setMenuItem] = React.useState<string>("home");
   
  const handleMenuClick = (menuItem: string) => {
    setMenuItem(activeMenu !== menuItem ? menuItem : "");
    onMenuClick()
  };

  const renderMenuByGroup = useCallback(() => {
    if (userGroup.length > 0) {
      if ( userGroup.includes('cidadão')) {
        return( <ListLinkSideBar
          activeMenu={activeMenu === "home"}
          icon={<ShareLocationOutlinedIcon fontSize="medium" />}
          text={"Rastreadores"}
          showText={!hiddenMenuLabel}
          link="/"
          handleClick={() => handleMenuClick("home")}
        />)
      } else if (userGroup.includes('integrador'))  {
        return( <>
          <ListLinkSideBar
            activeMenu={activeMenu === "tracks-register"}
            icon={<PersonAddAlt1OutlinedIcon fontSize="medium" />}
            text={"Cadastro do Cidadão"}
            showText={!hiddenMenuLabel}
            link="/citizen-register"
            handleClick={() => handleMenuClick("/citizen-register")}
          />
          <ListLinkSideBar
            activeMenu={activeMenu === "tracks-register"}
            icon={<AddLocationAltOutlinedIcon fontSize="medium" />}
            text={"Instalar Rastreador"}
            showText={!hiddenMenuLabel}
            link="/tracks-register"
            handleClick={() => handleMenuClick("tracks-register")}
          />
          {/* <ListLinkSideBar
            activeMenu={activeMenu === "/operator"}
            icon={<GroupsOutlinedIcon fontSize="medium" />}
            text={"Operador"}
            showText={mouseIsOver}
            link="/operator"
            handleClick={() => handleMenuClick("/operator")}
          />
          <ListLinkSideBar
            activeMenu={activeMenu === "/entry"}
            icon={<SupportAgentOutlinedIcon fontSize="medium" />}
            text={"Teleatendente"}
            showText={mouseIsOver}
            link="/entry"
            handleClick={() => handleMenuClick("/entry")}
          /> */}
          <ListLinkSideBar
            activeMenu={activeMenu === "/scheduling"}
            icon={<CalendarMonthOutlinedIcon fontSize="medium" />}
            text={"Agendamentos"}
            showText={!hiddenMenuLabel}
            link="/scheduling"
            handleClick={() => handleMenuClick("/scheduling")}
          />
        </>)
      }
    }
  }, [userGroup, hiddenMenuLabel]);

  

  return (
    <List>
      {renderMenuByGroup()}
    </List>
  );
}
