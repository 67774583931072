import React, { useEffect, useState } from "react";
import {
  UseFormGetValues,
  UseFormRegister,
  Controller,
  UseFormSetValue,
  FieldValues,
  UseFormWatch,
} from "react-hook-form";
import InputField from "../../../Field";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { InputAdornment, IconButton } from "@mui/material";

interface CrudTextFieldProps {
  readonly register: UseFormRegister<any>;
  readonly name?: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly control?: any;
  readonly label?: string;
  required?: boolean;
  messageError?: string;
  value?: string;
  readonly setValue: UseFormSetValue<any>;
  disabled?: boolean;
  id?: string;
  readonly watch: UseFormWatch<FieldValues>;
  readonly fieldToCompare: string | null;
}

export default function PasswordConfirmFieldForm({
  label="Confirme a Senha",
  register,
  control = {},
  name="confirm-password",
  required = true,
  messageError = "",
  value="",
  setValue,
  disabled = false,
  id = "confirm-password-input",
  watch,
  fieldToCompare = null,
}: CrudTextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);
 

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setValue(name, value);
    }
  }, [value]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const getHelpText = (error:any) =>{
    if(required){
      if(error !== undefined){
        return error.message
      }
    }
    return ''
  
  }

  const showError = (error:any) =>{
    if(required){
      if(error !== undefined){
        return true
      }
    }
    return false
  }

  return (
    <Controller
      {...register(
        name, 
        { 
          required: { 
            value: required,
            message: messageError === '' ? 'O campo confirmação de senha não pode ser vazio' : messageError,
          },
      
          validate: {
            compare: ((value) => {
              if(fieldToCompare){
                if(value !== watch(fieldToCompare)){
                  return messageError === '' ? "As senhas não combinam." : messageError
                }  
              }
               
            }),
           
           
          }
        })
      }
      name={name}
      control={control}
      defaultValue={value}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputField
            helperText={getHelpText(error)}
            error={showError(error)}
            {...field}
            label={label}
            name={name}
            disabled={disabled}
            id={id}
            type={showPassword ? "text" : "password"}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        );
      }}
    />
  );
}
