import PrivateRoute from "../../core/PrivateRouter";
import CreateEntry from "./pages/Entry/CreateEntry";
import ListEntry from "./pages/Entry/ListEntry";
import CreateOperator from "./pages/Operator/CreateOperator";
import ListOperator from "./pages/Operator/ListOperator";
import ListScheduling from "./pages/Scheduling/ListScheduling";

export const licensedRoutes = [
    { path: '/operator-register', element: <PrivateRoute Group={['integrador']}><CreateOperator /></PrivateRoute> },
    { path: '/operator', element: <PrivateRoute Group={['integrador']}><ListOperator /> </PrivateRoute> },
    { path: '/entry', element: <PrivateRoute Group={['integrador']}><ListEntry /> </PrivateRoute> },
    { path: '/entry-register', element: <PrivateRoute Group={['integrador']}><CreateEntry /></PrivateRoute> },
    { path: '/scheduling', element: <PrivateRoute Group={['integrador']}><ListScheduling /></PrivateRoute> },
];