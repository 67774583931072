import React, {useEffect, useState} from 'react';
import {
 DataGrid,
 GridSortModel,
 GridColDef,
 GridRowModel,
} from '@mui/x-data-grid';
import {useNavigate} from 'react-router-dom';
import {Card} from '@mui/material';
import {useHookstate} from '@hookstate/core';
import SearchField from '../Filters/SearchField';
import Grid from '@mui/material/Grid2'

interface IDataTable {
 id: any;
 first_name: string;
 last_name: string;
 email: string;
 username: string;
 roles: string;
 //cpf: string;
 //birthDate:string;
 enabled: string;
 //instituicao:string;
}

interface ICrudIndex {
    columns: GridColDef[]
}

export default function CrudIndex({columns}: ICrudIndex) {
 const pageSizeOptions = [5, 15, 50, 100];

 const [data, setData] = useState<IDataTable[]>([]);
 const [isLoading, setIsLoading] = useState<boolean>(true);
 const [page, setPage] = useState(0);
 const [limit, setLimit] = useState(pageSizeOptions[1]);
 const [rowCount, setRowCount] = useState(0);
 const [filters, setFilters] = useState<any | null>(null);
 const [sort, setSort] = useState<GridSortModel>([]);
 const [rowId, setRowId] = useState<any>();
 const [rows, setRows] = useState<readonly GridRowModel[]>([]);
 const [openModalPassword, setOpenModalPassword] = useState<boolean>(false);
 const [password, setPassword] = useState<string>('');

 const openDialog = useHookstate(false);
 const openDialogBox = useHookstate(false);
 const dialogBoxContent = useHookstate('');
 const dialogBoxTitle = useHookstate('');

 const navigate = useNavigate();

 const getData = async () => {
  setIsLoading(true);
//   const {
//    data: {data, rowsCount},
//   } = (await getListOfUsers(limit, page, filters)) as any;

//   if (data !== undefined) {
//    if (data.length > 0) {
//     setData(filterData(data));
//     setRowCount(rowsCount);
//    } else {
//     setData([]);
//    }
//   }
  setIsLoading(false);
 };

 const filterData = (dataToFilter: Array<any>) => {
  const newData: Array<IDataTable> = [];
  dataToFilter.forEach((list, index: number) => {
   const dataList: IDataTable = {
    id: list?.id,
    first_name: list.firstName,
    last_name: list.lastName,
    email: list.email,
    username: list.username,
    //cpf: list.cpf,
    //birthDate: list.birthDate,
    roles: list.roles,
    enabled: list.enabled ? 'Habilitado' : 'Desabilitado',
    //instituicao: list.instituicao
   };

   newData.push(dataList);
  });
  return newData;
 };

 function handleEdit(id: string) {
  navigate(`/users/edit/${id}`);
 }

 function handleDelete(id: number) {
  handleDialogOpen();
  setRowId(id);
 }

 const handleDialogOpen = () => {
  openDialog.set(true);
 };

 const handleDialogClose = () => {
  openDialog.set(false);
 };

 const handleDialogCloseFunction = () => {
//   deleteUser(rowId).then(() => {
//    getData();
//    handleDialogClose();
//   });
 };

 const handleDialogBoxClose = () => {
  openDialogBox.set(false);
 };


 const modalClosedPassword = () => {
  setOpenModalPassword(false);
  setPassword('');
 };

 const getDataBySearching = async (text: string) => {
  if (text.length > 0) {
   const filtersCopy = {...filters};
   filtersCopy['like'] = text;
   setFilters(filtersCopy);
  }
 };

 const clearSearching = () => {
  const filtersCopy = {...filters};
  delete filtersCopy['like'];
  setFilters(filtersCopy);
 };

 useEffect(() => {
  if (filters !== null || page !== 0 || limit !== null) {
   getData();
  }
 }, [filters, page, limit]);

 useEffect(() => {
  getData();
 }, []);

 return (
  <>
   <Card sx={{p: 3, width: '100%', borderRadius: '24px', mt: 5}}>
    <Grid container>
     <Grid size={12}>
      <SearchField
       onTextIsSending={getDataBySearching}
       label={'Buscar'}
       onClearSearch={clearSearching}
      />
     </Grid>
     <Grid size={12}>
      <DataGrid
       localeText={{
        MuiTablePagination: {
         labelDisplayedRows: ({from, to, count}) =>
          `mostrando ${data.length} registros de ${count}`,
        },
       }}
       sx={{
        border: 0,
        '& .MuiDataGrid-cell:hover': {
         color: 'primary.main',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
         fontWeight: 700,
        },
       }}
       autoHeight={true}
       pageSizeOptions={pageSizeOptions}
       loading={isLoading}
       rows={data}
       columns={columns}
       initialState={{
        pagination: {paginationModel: {pageSize: limit}},
       }}
       onPaginationModelChange={({page, pageSize}) => {
        setPage(page);
        setLimit(pageSize);
       }}
       pagination
       disableRowSelectionOnClick
       paginationMode={'server'}
       filterMode={'server'}
       rowCount={rowCount}
       slotProps={{
        pagination: {
         labelRowsPerPage: 'Usuários por página',
        },
       }}
       disableColumnSelector={true}
      />
     </Grid>
    </Grid>
   </Card>
   {/* <ConfirmDialog
    handleAgree={handleDialogCloseFunction}
    handleClose={handleDialogClose}
    open={openDialog}
    title={translate('Confirmation')}
    content={translate('Confirm removal?')}
   /> */}
   {/* <SimpleDialogBox
    handleClose={handleDialogBoxClose}
    open={openDialogBox}
    title={dialogBoxTitle}
    content={dialogBoxContent}
   />
   <PasswordDialog
    openModal={openModalPassword}
    password={password}
    onCloseModal={modalClosedPassword}
   /> */}
  </>
 );
}