import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import { Avatar, Typography } from "@mui/material";
import MainLayout from "../../../core/Layout/MainLayout";
import { useNavigate } from "react-router-dom";
import {useAuth} from 'react-oidc-context';

import { getUserIdKeyCloak } from "../../../core/Users/UsersCore";
import { changePasswordUserKeyCloak } from "../services/UsersAuthService";
import Alert from '@mui/material/Alert';
import { useForm } from 'react-hook-form';
import TitleLayout from "../../../core/Layout/TitleLayout";
import ContainerLayout from "../../../core/Layout/ContainerLayout";
import PasswordFieldForm from "../../../components/Form/components/Fields/PasswordFieldForm";
import LockIcon from '@mui/icons-material/Lock';
import PasswordConfirmFieldForm from "../../../components/Form/components/Fields/PasswordConfirmFieldForm";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import PrimaryResponsiveButton from "../../../components/Buttons/PrimaryResponsiveButton";
import SecondaryResponsiveButton from "../../../components/Buttons/SecondaryResponsiveButton";

function UserChangePasswordPage() {
  const navigate = useNavigate();
  const auth = useAuth();
  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: {isValid},
    watch,
    getValues
  } = useForm({ mode: "all" });

  const [messageError, setMessageError] = useState<any>("");
  const [error, setError] = useState(false);
  const [processing, setProcessing] = useState<boolean>(false);

  const [hasError, setHasError] = useState<boolean>(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);




  const submitForm = async (body: any) => {
    setError(false);
    setMessageError("");
    setProcessing(true);
    setShowMessage(false)
    console.log(body, 'body')
    const idUserKeyCloak = await getUserIdKeyCloak()
    if(idUserKeyCloak){
      const response  = await changePasswordUserKeyCloak(idUserKeyCloak, body.new_password) as any
      console.log(response.hasError, 'response')
      if(!response?.hasError){
        //handleLogout()
        setHasError(false)
        handleLogout()
        setTimeout(() => navigate("/"), 600);
      }else{
        setHasError(true)
      }
    }else{
      setHasError(true)
      console.log('Ocorreu um erro ao pegar o id do usuário sessão do keycloak')
    }

    setShowMessage(true)
    setProcessing(false);
  };


  const handleLogout = async () => {
    var host = window.location.protocol + "//" + window.location.host;
    auth.signoutRedirect({post_logout_redirect_uri: host})

  };

  return (
    <MainLayout>
       <TitleLayout >
          Alterar senha
        </TitleLayout>
        <Grid container  sx={{padding: 2}} justifyContent={"center"} >
          <Grid size={{xs:12, md:6}}>
            <ContainerLayout minHeight="auto" loading={processing}>
              <Grid container justifyContent={"center"}>
                <Avatar
                  sx={{ width: 56, height: 56 }}
                >
                  <LockIcon />
                </Avatar>
              </Grid>

              {showMessage && (
                <Grid container sx={{padding: 2}}>  
                  {!hasError ? (
                    <Alert severity="success" sx={{width: '100%'}}>Senha Alterada Com Sucesso.</Alert>
                  ):(
                    <Alert severity="error" sx={{width: '100%'}}>Ocorreu um erro ao mudar a senha.</Alert>
                  )}
                </Grid>
              )}
              <Grid container component="form" sx={{padding: 2}} spacing={2} onSubmit={handleSubmit(submitForm)}>
                  <Grid size={{xs:12}}>
                    <PasswordFieldForm
                      label='Nova Senha'
                      register={register}
                      control={control}
                      name='new_password'
                      value={getValues('new_password')}
                      setValue={setValue}
                      id='new_password'
                      
                    />
                  </Grid>
                  <Grid size={{xs:12}}>
                    <PasswordConfirmFieldForm
                      register={register}
                      control={control}
                      messageError={"As senhas não combinam."}
                      setValue={setValue}
                      id='repassword_input'
                      watch={watch}
                      fieldToCompare='new_password'
                      value={getValues('confirm-password')}
                      
                    />
                  </Grid>
                  <Grid container sx={{pt: 1, width:'100%'}} justifyContent={"center"}>
               
                    <SecondaryResponsiveButton onClick={()=>navigate('/')}>
                      Cancelar
                    </SecondaryResponsiveButton>
                 
                    <PrimaryResponsiveButton  type="submit"> 
                      Alterar
                    </PrimaryResponsiveButton>
              </Grid>   
            </Grid>  
  
          
            </ContainerLayout>
          </Grid> 
        </Grid>    

    </MainLayout>
  );
}

export default UserChangePasswordPage;
