import { State, useHookstate } from "@hookstate/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import FilterAutocompleteStatusScheduling from "../../../../components/Filters/FilterAutocompleteStatusScheduling";
import CancelButton from "../../../../components/Buttons/CancelButton";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import { useNavigate } from "react-router-dom";
import InputField from "../../../../components/Field";
import notify from "../../../../utils/notify";
import { IScheduling } from "../../../Tracks/interface";

interface IStatusDialog {
  readonly open: State<boolean>;
  readonly mock: IMock | IScheduling | null;
}

export interface IMock {
  readonly name: string;
  readonly type_service: string;
  readonly cpf: string;
  readonly hour: string;
  readonly plate: string;
  readonly status: string;
}

export default function StatusDialog({ open, mock }: IStatusDialog) {
  const status = useHookstate("");
  const comment = useHookstate("");
  const commentError = useHookstate(false);
  const navigate = useNavigate();

  // useEffect(() => {
  //   mock && status.set(mock.status);
  // }, [mock]);

  const handleConfirmClick = () => {
    if (comment.get().length) {
      notify({message: 'Status do atendimento alterado com sucesso!', type: 'success'})
      setTimeout(() => navigate(-1), 1000);
    } else {
      commentError.set(true);
    }
  };

  return (
    <Dialog
      open={open.get()}
      onClose={() => open.set(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={"xs"}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {mock?.type_service} - {mock?.name}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Você está cancelando o registro desse serviço. Selecione o motivo abaixo:
        </DialogContentText>
        <Grid paddingTop={2}>
          <FilterAutocompleteStatusScheduling
            onStatusChange={(newStatus) => status.set(newStatus)}
            status={status.get()}
          />
        </Grid>
        {/* {status.get() === "Não realizado" && ( */}
          <Grid paddingTop={2}>
            <InputField
              value={comment.get()}
              label="Comentário"
              helperText={commentError.get() && 'É obrigatório colocar o motivo do serviço não ter sido realizado.'}
              rows={5}
              error={commentError.get()}
              multiline
              required
              onChange={(event: { target: { value: string } }) => {
                event.target.value.length && commentError.set(false)
                comment.set(event.target.value);
              }}
            />
          </Grid>
        {/* )} */}
      </DialogContent>
      <DialogActions>
        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <CancelButton onCancelClick={() => open.set(false)} />
        </Grid>

        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <ConfirmButton onConfirmClick={handleConfirmClick} />
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
