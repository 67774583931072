import React from 'react';
import { UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form/dist/types/form";
import DateField from '../DateField';
import Grid from '@mui/material/Grid2';
import PasswordField from '../PasswordField';
import { FieldValues } from 'react-hook-form';
import TextField from '../TextField';

interface ICrudGeneratorFields {
  readonly fields: ReadonlyArray<Object>,
  readonly register: UseFormRegister<any>,
  readonly control: any,
  column: number,
  readonly setValue: UseFormSetValue<any>,
  readonly watch: UseFormWatch<FieldValues>
}

export default function FormGeneratorFields({ fields, register, control, column, setValue, watch }: ICrudGeneratorFields) {

  const getField = (field: any) => {
    if (field.type === 'string') {
      return <TextField
        label={field.label}
        register={register}
        control={control}
        name={field.name}
        messageError={field?.messageError}
        required={field.required}
        value={field.value}
        setValue={setValue}
        disabled={field.disabled !== undefined ? field.disabled : false}
        id={field?.id}
        mask={field.mask}
      />
    } else if (field.type === 'date') {
      return <DateField
        label={field.label}
        register={register}
        control={control}
        name={field.name}
        messageError={field?.messageError}
        required={field.required}
        value={field?.value}
        setValue={setValue}
        id={field?.id}
      />
    } else if (field.type == 'password') {
      return <PasswordField
      label={field.label}
      register={register}
      control={control}
      name={field.name}
      messageError={field?.messageError}
      required={field.required}
      value={field.value}
      setValue={setValue}
      disabled={field.disabled !== undefined ? field.disabled : false}
      id={field?.id}
      watch={watch}
      dependent={field.dependent}
    />
    }
  }

  return (
    <>
      <Grid container spacing={2} sx={{width: '100%'}}>
        {fields.map((field, index) =>
          <Grid size={{xs: 12, sm: 6, md: 12 / column }} key={index}>
            {getField(field)}
          </Grid>
        )}
      </Grid>
    </>
  )
}