import React from "react";
import Grid from "@mui/material/Grid2";
import { Button, Tooltip, Typography } from "@mui/material";
import CrudIndex from "../../../../components/CrudIndex";
import {
  GridColDef,
  GridRowParams,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../../../core/Layout/MainLayout";

export default function ListOperator() {
    const navigate = useNavigate()
  const columns: GridColDef[] = [
    { field: "first_name", headerName: "Nome", flex: 1 },
    {
      field: "last_name",
      headerName: "Sobrenome",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    // {
    //   field: "roles",
    //   headerName: "Permissão",
    //   flex: 1,
    // },
    {
      field: "cpf",
      headerName: "CPF",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Telefone",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params: GridRowParams) => [
        <Tooltip title={"Editar"}>
          <GridActionsCellItem
            key={0}
            icon={<EditOutlinedIcon />}
            onClick={() => {}}
            label={"Editar"}
            id="btn-edit-user"
            className="btn-edit-user"
          />
        </Tooltip>,
        <Tooltip title={"Excluir"}>
          <GridActionsCellItem
            key={1}
            icon={<DeleteIcon />}
            onClick={() => {}}
            label={"Excluir"}
            id="btn-delete-user"
            className="btn-delete-user"
          />
        </Tooltip>,
        // <Tooltip title={'Resetar senha'}>
        //  <GridActionsCellItem
        //   key={1}
        //   icon={<LockResetOutlinedIcon />}
        //   onClick={() => handleReset(params.row.id)}
        //   label={'Resetar senha'}
        //   id="btn-resete-user"
        //   className="btn-resete-user"
        //  />
        // </Tooltip>,
      ],
    },
  ];

  return (
    <MainLayout>
      <Grid container>
        <Grid
          size={12}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
            paddingRight: 2,
          }}
        >
          <Grid size={8} sx={{ display: "flex" }}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: "bold",
                marginLeft: 4,
                marginTop: 4,
                paddingBottom: 1,
              }}
            >
              Operador
            </Typography>
          </Grid>
          <Grid size={4} sx={{ display: "flex" }}>
            <ConfirmButton
              label="Adicionar Operador"
              onConfirmClick={() => navigate('/operator-register')}
              sx={{ padding: 2 }}
            />
          </Grid>
        </Grid>
        <CrudIndex columns={columns} />
      </Grid>
    </MainLayout>
  );
}
