import Typography  from "@mui/material/Typography";

import React, { ReactNode } from "react";

type LayoutProps = {
    readonly children: ReactNode;
}

export default function InfoSumary({ children}: LayoutProps) {
    return (
        
    <Typography component='span' sx={{ color: 'text.secondary'}}>
        {children}
    </Typography>
    )
}
