import axios, { AxiosRequestConfig, AxiosError } from "axios";
import { getBaseUrl } from "../utils/net";
import {User} from 'oidc-client-ts';

export const mountUrlRequest = (url: string) => {
    const baseUrl = getBaseUrl();
    const urlRequest = baseUrl + url;
   return urlRequest;
}

export const authFetch = async ({
    url = '',
    method = 'POST',
    headers,
    data,
    params,
    timeout = 10000,
   }: AxiosRequestConfig) => {
    const user = getUser();
    const token = user?.access_token;
    const urlRequest = mountUrlRequest(url);
    try {
     const response = await axios(urlRequest, {
      method,
      headers: {
       ...headers,
       Accept: 'application/json',
       'Content-Type': 'application/json',
       'Accept-Language': 'pt-br,pt_br,pt_BR',
       Authorization: `Bearer ${token}`,
      },
      data,
      params,
      timeout,
     });
     return response;
    } catch (error) {
     const err = error as AxiosError;
     const statusCode = err.response?.status;
     const {error: errorMessage = ''} = err.response?.data as {error: string};
   
     if (
      statusCode == 400 ||
      statusCode == 401 ||
      statusCode == 403 ||
      statusCode == 409
     ) {
      if (error) {
       console.error('ERROR', errorMessage + ' authFetch: ', error);
       throw err.response?.data as {title: string};
      }
     } else if (statusCode === 404) {
      console.error('ERROR', 'authFetch_404: ', 'End point não encontrado');
     } else if (statusCode === 500) {
      console.error('ERROR', 'authFetch: ', 'Ocorreu um erro');
     }
   
     throw errorMessage;
    }
   };

   export function getUser() {
        const oidcStorage = localStorage.getItem(
            `oidc.user:https://auth.spocws.icu/realms/MOBILIDADE:mobilidade_segura`,
        );
        if (!oidcStorage) {
        return null;
        }
    
        return User.fromStorageString(oidcStorage);
   }

   export const fetchWithoutAuth = async ({
    url = '',
    method = 'POST',
    headers,
    data,
    params,
    timeout = 10000,
   }: AxiosRequestConfig) => {
    const urlRequest = mountUrlRequest(url);
    try {
     const response = await axios(urlRequest, {
      method,
      headers: {
       ...headers,
       Accept: 'application/json',
       'Content-Type': 'application/json',
       'Accept-Language': 'pt-br,pt_br,pt_BR',
      },
      data,
      params,
      timeout,
     });
     return response;
    } catch (error) {
     const err = error as AxiosError;
     const statusCode = err.response?.status;
     const {error: errorMessage = ''} = err.response?.data as {error: string};
   
     if (
      statusCode == 400 ||
      statusCode == 401 ||
      statusCode == 403 ||
      statusCode == 409
     ) {
      if (error) {
       console.error('ERROR', errorMessage + ' authFetch: ', error);
       throw err.response?.data as {title: string};
      }
     } else if (statusCode === 404) {
      console.error('ERROR', 'authFetch_404: ', 'End point não encontrado');
     } else if (statusCode === 500) {
      console.error('ERROR', 'authFetch: ', 'Ocorreu um erro');
     }
   
     throw errorMessage;
    }
   };
   