import React, { useEffect, useState } from "react";
import {
  UseFormGetValues,
  UseFormRegister,
  Controller,
  UseFormSetValue,

} from "react-hook-form";
import InputField from "../../../Field";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { InputAdornment, IconButton } from "@mui/material";

interface CrudTextFieldProps {
  readonly register: UseFormRegister<any>;
  readonly name: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly control?: any;
  readonly label?: string;
  required?: boolean;
  messageError?: string;
  value?: string;
  readonly setValue: UseFormSetValue<any>;
  disabled?: boolean;
  id?: string;
}

export default function PasswordFieldForm({
  label,
  register,
  control = {},
  name,
  required = true,
  messageError = "",
  value="",
  setValue,
  disabled = false,
  id = "simple-input",
}: CrudTextFieldProps) {
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setValue(name, value);
    }
  }, [value]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const getHelpText = (error:any) =>{
  
    if(required){
      if(error !== undefined){
        return error.message
      }
    }
    return ''
  
  }

  const showError = (error:any) =>{
    if(required){
      if(error !== undefined){
        return true
      }
    }
    return false
  }

  return (
    <Controller
      {...register(
        name, 
        { 
          required: { 
            value: required,
            message: messageError === '' ? 'O campo senha não pode ser vazio' : messageError,
          },
          minLength: {
            value: 8,
            message: "Tem que ter no mínimo 8 caracteres",
          },
          validate: {
            especial_characters: ((value) => {
               if(!/[@$!%?&*#]/gm.test(value)){
                 return "Necessário ao menos um caracter especial. Exemplos: !,?,@,*,&"
               }  
            }),
            uppercase:  ((value) => {
              if(!/[A-Z]/g.test(value)){
                return 'Tem que ter ao menos uma letra maiúscula.' 
              }  
            }),
            lowercase:  ((value) => {
              if(!/[a-z]/g.test(value)){
                return 'Tem que ter ao menos uma letra minúscula.' 
              }  
            }),
            number:  ((value) => {
              if(!/[.\d]/g.test(value)){
                return 'Tem que ter ao menos um número.' 
              }  
            }),
           
          }
        })
      }
      name={name}
      control={control}
      defaultValue={value}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputField
            helperText={getHelpText(error)}
            error={showError(error)}
            {...field}
            label={label}
            //required={required}
            name={name}
            disabled={disabled}
            id={id}
            type={showPassword ? "text" : "password"}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        );
      }}
    />
  );
}
