import { State, useHookstate } from "@hookstate/core";
import Grid from "@mui/material/Grid2";
import CancelButton from "../../../../components/Buttons/CancelButton";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import { useEffect, useState } from "react";
import { Box, Card, Typography } from "@mui/material";
import BaseButton from "../../../../components/Buttons/BaseButton";
import CameraModal from "../CameraModal";
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import { FileRejection, useDropzone } from "react-dropzone";
import notify from "../../../../utils/notify";
interface IUploadPhotoTab {
  readonly value: State<number>;
  readonly image: State<string | ArrayBuffer | ImageData | null>;
}

export default function UploadPhotoTab({ value, image }: IUploadPhotoTab) {
  const [file, setFile] = useState<string | ImageData | ArrayBuffer | null>();
  const openCamera = useHookstate(false);
  const maxSize = 5000000;
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'image/jpg': [],
    },
    maxFiles: 1,
    onDropAccepted: submitFile,
    onDropRejected: rejectFiles,
    multiple: false,
    maxSize
  });

  function submitFile(files: readonly File[]) {
    const file = files[0]
    const reader = new FileReader();
    reader.readAsDataURL(file)
    reader.addEventListener('loadend', () => {
      image.set(reader.result);
    })
  }

  function rejectFiles(fileRejections: readonly FileRejection[]) {
    fileRejections.forEach(fileRejection => {
      fileRejection.errors.forEach(err => {
        if (err.code === 'file-invalid-type') {
          notify({ message: 'Apenas são aceitos arquivos com extensão .jpg, .pgn e .jpeg', type: "error" })
          return
        } else if (err.code === 'file-too-large') {
          notify({ message: `Arquivo maior que ${maxSize / 1000000}MB`, type: 'error' })
          return
        }
      })
    })
  }

  useEffect(() => {
    setFile(image.get())
  }, [image.get()])

  return (
    <Grid
      container
      size={12}
      display={"flex"}
      flexDirection={"column"}
      sx={{ justifyContent: "flex-start", gap: 1 }}
    >
      <Typography>
        Adicione uma foto, com no máximo {maxSize / 1000000}MB, que mostre o rastreador instalado no veículo.
      </Typography>
      <Grid container size={12} display={'flex'} justifyContent={'center'} sx={{ minHeight: '30vh', marginTop: 2 }}>
        <Grid size={{ sm: 6, lg: 4 }}>
          {file ? (<Box
            component={"img"}
            sx={{
              maxHeight: { xs: "100%", sm: "80%", md: "100%" },
              maxWidth: { xs: "100%", sm: "80%", md: "100%" },
            }}
            src={file as string}
          />
          ) : (
            <Card variant="outlined" sx={{ height: '90%', display: 'flex',  paddingX: 1 }}>
              <div {...getRootProps({ className: 'dropzone' })} style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <input {...getInputProps()} />
                <p style={{ textAlign: "center" }}>Arraste e solte uma foto aqui ou clique para selecionar uma foto</p>
                <InsertPhotoOutlinedIcon fontSize="large" sx={{ height: '40%', width: '40%' }} />
              </div>
            </Card>
          )}
        </Grid>
      </Grid>

      <Grid container display={"flex"} size={12} justifyContent={"center"} gap={2} >
        <Grid size={{ xs: 12, sm: 4, md: 3, lg: 1 }}>
          <BaseButton size="small" variant="outlined" fullWidth onClick={() => openCamera.set(true)} >
            Tirar foto
          </BaseButton>
        </Grid>
        <Grid size={{ xs: 12, sm: 3, md: 2, lg: 1 }}>
          <BaseButton size="small" variant="outlined" fullWidth sx={{ justifyContent: "space-around" }} onClick={() => image.set(null)}>
            Excluir foto
          </BaseButton>
        </Grid>
      </Grid>
      <Grid
        container
        size={12}
        sx={{ gap: 1 }}
        display={"flex"}
        justifyContent="flex-end"
      >
        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <CancelButton
            stepMode
            onCancelClick={() => value.set(value.get() - 1)}
          />
        </Grid>

        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <ConfirmButton
            stepMode
            onConfirmClick={() => value.set(value.get() + 1)}
          />
        </Grid>
      </Grid>
      <CameraModal open={openCamera} setFile={setFile} image={image} />
    </Grid>
  );
}
