import React from 'react';

import BaseButton from './BaseButton';

type ButtonProps = {
    readonly children: React.ReactNode, 
    type?: "button" | "submit" | "reset", 
    onClick?: ()=> void
    disabled?: boolean 
    fullWidth?:boolean

}

export default function SecondaryButton({
    type="button", 
    onClick =()=>{},
    disabled= false, 
    children,
    fullWidth=false
}: ButtonProps) {    
    return (
        <BaseButton fullWidth={fullWidth} variant="outlined" type={type} onClick={onClick}  size="small" disabled={disabled} sx={{ borderColor: '#C5CCD6', color: '#7A8B9A'}}>
          {children}
        </BaseButton>
          
    )
}