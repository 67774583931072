import Grid from "@mui/material/Grid2";
import CancelButton from "../../../../components/Buttons/CancelButton";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import MapApp from "../../../../components/Map/MapApp";
import { State, useHookstate } from "@hookstate/core";
import { useEffect } from "react";
import { getLastTrackPosition } from "../../services";
import notify from "../../../../utils/notify";
import { CircularProgress, Typography } from "@mui/material";

interface IConfirmLocationTab {
  readonly value: State<number>;
  readonly trackCode: string;
}

export default function ConfirmLocationTab({
  value,
  trackCode,
}: IConfirmLocationTab) {
  const lat = useHookstate<number | null>(null);
  const long = useHookstate<number | null>(null);
  const loading = useHookstate(false);

  useEffect(() => {
    loading.set(true)
    getLastTrackPosition(trackCode)
      .then((data) => {
        if (data && data.location?.length) {
          lat.set(data.location[0]);
          long.set(data.location[1]);
        } else {
          lat.set(null);
          long.set(null);
        }
      })
      .catch((err) => {
        notify({
          message: `Algo deu errado, tente novamento mais tarde.`,
          type: "error",
        });
        console.error(err);
      }).finally(() => loading.set(false));
  }, []);
  return (
    <>
      <Grid container display={'flex'} justifyContent={'center'} alignItems={'center'}>
        {loading.get() ? (
          <CircularProgress />
        ) : lat.get() && long.get() ? (
          <MapApp
            lat={lat.get() ?? 0}
            lng={long.get() ?? 0}
            zoom={20}
            height={"50vh"}
          />
        ) : (
          <Typography
            align="center"
            sx={{ color: "#a63939", fontSize: "1rem" }}
          >
            Não foi identificado o recebimento dos dados de localização deste
            rastreador.
          </Typography>
        )}
      </Grid>
      <Grid
        container
        size={12}
        sx={{ gap: 1 }}
        display={"flex"}
        justifyContent="flex-end"
        paddingTop={2}
      >
        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <CancelButton
            stepMode
            onCancelClick={() => value.set(value.get() - 1)}
          />
        </Grid>

        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <ConfirmButton
            stepMode
            onConfirmClick={() => !loading.get() && value.set(value.get() + 1)}
          />
        </Grid>
      </Grid>
    </>
  );
}
