import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface IAccreditedStepType {
  activeStep: number
  handleNext: () => void
  handleBack: () => void
  setSteps: (steps: any) => void
  isLastStep: boolean,
  lengthStep: number,
  steps: Array<any>,
  navigateStep: (index:number)=>void
}

type AccreditedStepContextProviderProps = {
  readonly children: ReactNode;
}

export const AccreditedStepContext = createContext<IAccreditedStepType>({} as IAccreditedStepType);

export function AccreditedStepContextProvider({children}: AccreditedStepContextProviderProps) {
  const [steps, setSteps] = React.useState<any>([]);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [lengthStep, setLengthStep] = React.useState<number>(0);
  const [isLastStep, setIsLastStep] = React.useState<boolean>(false);

  const handleNext = () => {
    if(activeStep < lengthStep - 1){
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if(activeStep > 0)
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }; 

  const navigateStep = (index:number) => {
    if(index >= 0 && index < lengthStep)
      setActiveStep(index);
  }; 

  useEffect(()=>{
    if(steps !== null && steps !== undefined){
      setLengthStep(steps.length)
    }
  },[steps])

  useEffect(()=>{
    setIsLastStep(activeStep === lengthStep-1)
  },[activeStep])

    return (
      <AccreditedStepContext.Provider 
        value={{
          activeStep,
          handleNext,
          handleBack,
          setSteps,
          isLastStep,
          lengthStep,
          steps,
          navigateStep
        }}>
        {children}
      </AccreditedStepContext.Provider>
    );
  }

  export function useAccreditedStep() {
    return useContext(AccreditedStepContext);
  }