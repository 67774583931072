import axios from "axios";
import { authFetch, fetchWithoutAuth } from "../../../services/fetch";
import { baseUrlKeyCloak, getUserTokenKeyCloak } from "../../../core/Authentication/AuthenticationCore";

export interface IError {
    readonly type: string;
    readonly title: string;
    readonly details: string;
    readonly status: number;
    readonly instance: string;
    readonly invalid_params: {
        readonly name: string;
        readonly reason: string;
    }[]
}

export async function changePasswordUserKeyCloak(id: string, password: string) {
    try {
      
        const token = await getUserTokenKeyCloak();   
        const response = await axios(`${baseUrlKeyCloak}/admin/realms/MOBILIDADE/users/${id}/reset-password`,
            {
                method: 'PUT',
                 data: { type: "password", temporary: false, value: password },
                 headers: {
        
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    //'Accept-Language': 'pt-br,pt_br,pt_BR',
                    Authorization: `Bearer ${token}`,
                   },
           
           });
        //return response;
        return {hasError: false, response}
       
    } catch (error) {
        console.error('~ changePasswordUserKeyCloak: ', error);
        return {hasError: true, error: error as IError}
    }
}