import { createTheme} from "@mui/material";

const ThemeApp = createTheme({
  palette: {
  background: {
    default: '#F7F7F7',
  },
  primary: {
    light: '#ACE9E7',
    main: '#009490',
    dark: '#016F6C',
    contrastText: '#FEFEFE',
  },
  secondary: {
    light: '#F4AB6C',
    main: '#F28322',
    dark: '#733E10',
  },
  error: {
    light: '#e57373',
    main: '#f44336',
    dark: '#d32f2f',
  },
  warning: {
    light: '#ffb74d',
    main: '#ffa726',
    dark: '#f57c00',
  },
  info: {
    light: '#4fc3f7',
    main: '#29b6f6',
    dark: '#0288d1',
  },
  success: {
    light: '#81c784',
    main: '#66bb6a',
    dark: '#388e3c',
  },
  
  text: {
    primary: "#121212",
    secondary: '#575B64'
  },

},
typography: {
  subtitle1: {
    fontSize: '1rem',
  },
  body1: {
    fontSize: '1rem',
  },
  body2: {
    fontSize: '0.875rem',
  },
  h1:{
    fontSize: '3.5rem',
  },
  h2:{
    fontSize: '3rem',
  },
  h3:{
    fontSize: '2.5rem',
  },
  h4:{
    fontSize: '2rem',
  },
  h5:{
    fontSize: '1.5rem',
  },
  h6:{
    fontSize: '1.25rem',
  },
}
});


export default ThemeApp