import React from 'react';
import Grid from '@mui/material/Grid2';
import { Typography } from '@mui/material';
import MainLayout from '../../core/Layout/MainLayout';
import ContainerLayout from '../../core/Layout/ContainerLayout';

function NoPermissionPage() {
  return (
    <MainLayout>
      <ContainerLayout>
        <Grid container justifyContent={"center"} alignItems={'center'} sx={{height: '80vh'}}>
          <Typography variant="h3" component={"p"} align='center'>
            Acesso Não Permitido
          </Typography> 
        </Grid>
      </ContainerLayout>
    </MainLayout>
 
 
  );
}

export default NoPermissionPage;
