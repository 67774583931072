import { Button, SxProps, Theme } from "@mui/material";

interface TextButtonProps {
  readonly onClick?: () => void;
  readonly type?: "button" | "submit" | "reset";
  readonly label: string;
  readonly size?: "medium" | "small" | "large";
  readonly sx?: SxProps<Theme>;
  readonly colorButton?: "primary" | "inherit" | "secondary" | "success" | "error" | "info" | "warning";
}

export default function TextButton({
  onClick,
  type = "button",
  label,
  size = 'medium',
  sx,
  colorButton = 'primary'
}: TextButtonProps) {
  return (
    <Button
      type={type}
      onClick={onClick}
      variant="text"
      size={size}
      fullWidth
      color={colorButton}
      sx={{
        textTransform: 'none',
        textDecorationLine: 'underline',
        color: 'text.primary',
        ':hover': {
          bgcolor: 'transparent',
          color: 'primary.main'
        },
        ...sx
      }}
    >
      {label}
    </Button>
  );
}
