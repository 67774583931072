import { State, useHookstate } from "@hookstate/core";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useRef } from "react";
import { Camera, CameraType } from "react-camera-pro";
import { CameraAltOutlined } from "@mui/icons-material";
import FlipCameraIosOutlinedIcon from "@mui/icons-material/FlipCameraIosOutlined";

interface IStatusDialog {
  readonly open: State<boolean>;
  readonly setFile: React.Dispatch<React.SetStateAction<string | ImageData | null | ArrayBuffer | undefined>>;
  readonly image: State<string | ArrayBuffer |ImageData| null>;

}

export default function CameraModal({ open, setFile, image }: IStatusDialog) {
  const cameraRef = useRef<CameraType>(null);
  const numberCameras = useHookstate(0);

  const errorMessages = {
    noCameraAccessible: "Não foi possível identificar a câmera do aparelho.",
    permissionDenied: "Permissão do uso de câmera negada.",
    switchCamera: "Não foi possível trocar de câmera",
  };

  return (
    <Dialog
      open={open.get()}
      onClose={() => open.set(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
        <Camera
          ref={cameraRef}
          errorMessages={errorMessages}
          aspectRatio={3 / 4}
          numberOfCamerasCallback={(cameras) => numberCameras.set(cameras)}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: -2,
        }}
      >
        <Grid size={8} display={'flex'} gap={4}>
          <IconButton
            size="large"
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              ":hover": {
                color: "primary.main",
                backgroundColor: 'gray.300'
              },
            }}
            onClick={() => {
                const photo = cameraRef.current?.takePhoto()
                setFile(photo)
                image.set(photo ?? null)
                open.set(false)
            }}
          >
            <CameraAltOutlined fontSize="large" />
          </IconButton>
          {numberCameras.get() > 1 && (
            <IconButton
              size="large"
              onClick={() => cameraRef.current?.switchCamera()}
              sx={{
                backgroundColor: "primary.main",
                color: "white",
                ":hover": {
                  color: "primary.main",
                  backgroundColor: 'gray.300'
                },
              }}
            >
              <FlipCameraIosOutlinedIcon fontSize="large" />
            </IconButton>
          )}
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
