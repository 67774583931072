import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import logo_emergencia from '~/assets/imgs/logo_emergencia.png'
import { Box } from '@mui/material';

export interface LoadingFeedbackProps {
  readonly open: boolean;
 }

export default function SplashFeedback({open}: LoadingFeedbackProps) {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: 'rgb(0 0 0 / 30%)' }}
      open={open}
    >
      <Box sx={{
         animationName: 'splash',
         animation: 'splash  1.4s linear infinite', 
         //borderRadius: '50%',
         //p:1,
         //background: '#000',
          '@keyframes splash' : {
          '0% ': { transform: 'rotate(0deg)'},
       
          '100%': { transform: 'rotate(360deg)' }
         },
        
      }}>
        <CircularProgress color="primary" size={150}/>
      </Box>
    </Backdrop>
  )
}
