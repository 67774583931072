import React, { useState } from "react";
import Grid from "@mui/material/Grid2";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FormCrud from "../../../../components/Form/components/FormCrud";
import GridForm from "../../../../components/Form/components/GridForm";
import MainLayout from "../../../../core/Layout/MainLayout";

function CreateOperator() {
  const navigate = useNavigate();
  const [messageError, setMessageError] = useState<any>("");
  const [error, setError] = useState(false);
  const [fields, setFields] = useState<Array<any>>([
    {
      name: "full_name",
      type: "string",
      label: "Nome Completo",
      messageError: "O nome é obrigatório",
      required: true,
      value: "",
      id: "full_name_input",
    },
    {
      name: "cpf",
      type: "string",
      label: "CPF",
      required: true,
      messageError: "O CPF é obrigatório",
      value: "",
      id: "cpf_input",
      mask: "999.999.999-99",
    },
    {
      name: "phone",
      type: "string",
      label: "Telefone",
      required: true,
      messageError: "O telefone é obrigatório",
      value: "",
      id: "phone_input",
      mask: "(99) 99999-9999",
    },
    {
      name: "email",
      type: "string",
      label: "Email",
      required: true,
      value: "",
      id: "email_input",
    },
    {
      name: "birth_date",
      type: "date",
      label: "Data de Nascimento",
      model: "Nascimento",
      messageError: "A Data de nascimento é obrigatório",
      required: true,
      value: "",
      id: "birth_date_input",
    },
    // { name: 'password', type: 'password', label: 'Senha', messageError: 'A senha deve ter, no mínimo 8 caracteres, letra maiúscula e minúscula, números e caracteres especiais.', required: true, value: '', id: 'password_input', inputType: 'password'  },
    // { name: 'repassword', type: 'password', label: 'Confirme a senha', messageError: 'As senhas não combinam.', required: true, value: '', id: 'repassword_input', inputType: 'password', dependent: 'password' },
  ]);

  const submitForm = async () => {};

  return (
    <MainLayout>
      <Grid container>
        <Grid size={12}>
          <Typography
            variant="h5"
            sx={{ fontWeight: "bold", ml: 4, mt: 4, display: "flex" }}
          >
            Cadastro do Operador
          </Typography>
        </Grid>
        <GridForm>
          <FormCrud
            fields={fields}
            onSubmitForm={submitForm}
            messagesError={messageError}
            error={error}
            onCancelClick={() => navigate("/operator")}
            onConfirmClick={() => {}}
            column={4}
          />
        </GridForm>
      </Grid>
    </MainLayout>
  );
}

export default CreateOperator;
