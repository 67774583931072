import React, { useState } from 'react';
import Grid from '@mui/material/Grid2';
import {  Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MainLayout from '../../../core/Layout/MainLayout';
import ContainerLayout from '../../../core/Layout/ContainerLayout';
import CardAccredited from '../components/Card/CardAccredited';
import TitleLayout from '../../../core/Layout/TitleLayout';
import CreateButton from '../../../components/Buttons/CreateButton';


export default function ListAccreditedPage() {
  const navigate = useNavigate();
  const array = [
    {nome: 'Credenciada', cnpj:'99.654.277/0001-76'},
    {nome: 'Credenciada', cnpj:'99.654.277/0001-76'},
    {nome: 'Credenciada', cnpj:'99.654.277/0001-76'},
  ]

  return (
    <MainLayout>
     
        <TitleLayout >
          Lista do Credenciadas
        </TitleLayout>
        <Grid container justifyContent={'flex-end'} sx={{paddingBottom: 2}}>
            <CreateButton
                to={'/credenciadas/criar'} >
                Adicionar credenciada
            </CreateButton> 
          </Grid>
        <ContainerLayout>
         
        {array.length > 0? 
        (
          <Grid container spacing={3}>
            {array.map(accredited=>
              <Grid size={{xs: 12, sm:6, md: 6, lg: 4}}>
                <CardAccredited {...accredited}/>
              </Grid>
            )}
          </Grid>
        ):(
          <Grid container alignItems={"center"} justifyContent={"center"} sx={{height: '80vh'}}>
            <Typography align="center" sx={{ color: '#a63939', mb: 1 , fontSize: '1rem'}}>
              Você ainda não tem nenhum rastreador cadastrado. Vá até o posto de uma credenciada com seu veículo e o equipamento, para realizar o cadastro.
            </Typography>
          </Grid>
        )}
      </ContainerLayout>
    </MainLayout>



  );
}
