import React, { ReactNode } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { Button } from '@mui/material'
import { State } from '@hookstate/core'

export interface ConfirmDialogProps {
  readonly open: any
  readonly title?: string
  readonly content?: string
  readonly handleClose?: () => void
  readonly handleAgree: () => void
  readonly closeLabel?: string
  readonly AgreeLabel?: string
  readonly loading?: State<boolean>
  readonly disabled?: boolean
  readonly moreInformation?: ReactNode
}

export default function ConfirmDialog({ open, title, content, handleClose, handleAgree, closeLabel = 'Cancel', AgreeLabel = 'Ok', loading, disabled, moreInformation }: ConfirmDialogProps) {
  return (
    <Dialog
      open={open.get()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={'xs'}
      fullWidth
    >
      {title && (
        <DialogTitle id="alert-dialog-title"  >
          {title}
        </DialogTitle>
      )}

      {content || moreInformation && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
            {moreInformation}
          </DialogContentText>
        </DialogContent>
      )}

      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'end',
          alignItems: 'center',
          gap: 2,
          padding: '16px 24px'
        }}
        disableSpacing={true}
      >
        <Button
          variant="contained"
          onClick={() => handleAgree()}
          autoFocus
          disabled={disabled}
        >
          {AgreeLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
