import Grid from "@mui/material/Grid2";
import { Tab, Tabs, Typography } from "@mui/material";
import MainLayout from "../../../../core/Layout/MainLayout";
import GridCrud from "../../../../components/Form/components/GridForm";
import TabPanel from "../../../../components/TabPanel";
import { ICitizen } from "../../components/SearchCitizenTab";
import { useHookstate } from "@hookstate/core";
import TrackFormTab from "../../components/TrackFormTab";
import KeywordRegisterTab from "../../components/KeywordRegisterTab";
import SummaryTab from "../../components/SummaryTab";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { createTracker } from "../../services";
import notify from "../../../../utils/notify";
import ConfirmLocationTab from "../../components/ConfirmLocationTab";
import { useLocation, useNavigate } from "react-router-dom";
import { getCitizen } from "../../../../components/Filters/FilterAutocompleteCitizen/services";
import { useEffect } from "react";
import { removeMask } from "../../../../utils/stringsManipulations";
import TextButton from "../../../../components/Buttons/TextButton";
import StatusDialog from "../../../Licensed/components/StatusDialog";
import { IScheduling, ITrack } from "../../interface";
import ContainerLayout from "../../../../core/Layout/ContainerLayout";
import TitleLayout from "../../../../core/Layout/TitleLayout";
import SubtitleLayout from "../../../../core/Layout/SubtitleLayout";
import UploadPhotoTab from "../../components/UploadPhotoTab";

export default function SchedulingTracksRegisterPage() {
  const trackCode = useHookstate("");
  const keyword = useHookstate("");
  const confirmKeyword = useHookstate("");
  const citizen = useHookstate<string | null>(null);
  const citizenDetails = useHookstate<ICitizen | null>(null);
  const openConfirmDialog = useHookstate(false);
  const brand = useHookstate("");
  const { state } = useLocation();
  const { schedule }: { schedule: IScheduling } = state;
  const value = useHookstate(0);
  const plate = useHookstate(schedule.plate);
  const openStatusDialog = useHookstate(false);
  const trackChip = useHookstate("");
  const image = useHookstate<string | ArrayBuffer | ImageData | null>(null);

  const navigator = useNavigate();

  const translation = (title: any) => {
    const pt: any = {
      "A tracker with this plate or trackCode already exists":
        "Um rastreador com esse código ou associado a essa placa já existe",
    };

    return pt[title] ? pt[title] : title;
  };

  const submitForm = async () => {
    const brandObj =
      brand.get() && brand.get().length > 0 ? { brand: brand.get() } : {};

    const trackerData: ITrack = {
      keyword: keyword.get(),
      plate: plate.get(),
      trackCode: trackCode.get(),
      userId: citizen.get(),
      trackChip: trackChip.get(),
      ...brandObj,
    };

    createTracker(trackerData).then(({ hasError, data, error }) => {
      if (!hasError) {
        openConfirmDialog.set(true);
        notify({
          message: `Rastreador cadastrado com sucesso`,
          type: "success",
        });
      } else {
        if (error?.invalid_params && error.invalid_params.length) {
          error.invalid_params?.map((item: { reason: string }) =>
            notify({
              message: `${item.reason}`,
              type: "error",
            })
          );
        } else if (error?.details.length) {
          notify({
            message: `${translation(error.details)}`,
            type: "error",
          });
        } else {
          notify({
            message: `Algo deu errado, tente novamente mais tarde.`,
            type: "error",
          });
        }
      }
    });
  };

  function tabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    if (schedule && schedule.cpf) {
      getCitizen().then((data) => {
        const citizenFiltered = data.find(
          (item) => removeMask(item.cpf) === schedule.cpf
        );
        if (citizenFiltered) {
          citizenDetails.set(citizenFiltered);
          citizen.set(citizenFiltered?.id);
        } else {
          notify({ message: "Usuário não encontrado.", type: "error" });
        }
      });
    }
  }, [schedule]);

  return (
    <MainLayout>
      <Grid size={12}>
        <TitleLayout>Agendamentos {">"} Serviços</TitleLayout>
        <SubtitleLayout>
          {schedule.hour} | {schedule.type_service} - {schedule.name}
        </SubtitleLayout>
      </Grid>
      <Grid
        container
        size={{ lg: 1 }}
        sx={{ display: "flex", justifyContent: "flex-start" }}
      >
        <TextButton
          label="Cancelar Serviço"
          onClick={() => openStatusDialog.set(true)}
          sx={{ fontSize: 16, textAlign: "left" }}
        />
      </Grid>
      <ContainerLayout minHeight="50%">
        <Tabs value={value.get()} aria-label="basic tabs example">
          <Tab label="Cadastrar Rastreador" {...tabProps(0)} />
          <Tab label="Confirmar Localização" {...tabProps(1)} />
          <Tab label="Enviar Foto" {...tabProps(2)} />
          <Tab label="Chave de Rastreamento" {...tabProps(3)} />
          <Tab label="Ver Resumo" {...tabProps(4)} />
        </Tabs>
        <TabPanel value={value.get()} index={0}>
          <TrackFormTab
            value={value}
            trackId={trackCode}
            plate={plate}
            brand={brand}
            state={state}
            trackChip={trackChip}
          />
        </TabPanel>
        <TabPanel value={value.get()} index={1}>
          <ConfirmLocationTab value={value} trackCode={trackCode.get()} />
        </TabPanel>
        <TabPanel value={value.get()} index={2}>
          <UploadPhotoTab value={value} image={image} />
        </TabPanel>
        <TabPanel value={value.get()} index={3}>
          <KeywordRegisterTab
            value={value}
            keyword={keyword}
            confirmKeyword={confirmKeyword}
          />
        </TabPanel>
        <TabPanel value={value.get()} index={4}>
          <SummaryTab
            citizen={citizen.get()}
            citizenDetails={citizenDetails}
            plate={plate.get()}
            trackId={trackCode.get()}
            value={value}
            brand={brand.get()}
            confirmAction={() => submitForm()}
            trackChip={trackChip.get()}
          />
        </TabPanel>
      </ContainerLayout>
      <ConfirmDialog
        title="Rastreador cadastrado com sucesso"
        moreInformation={
          <Typography>O rastreador do cidadão já está ativo.</Typography>
        }
        open={openConfirmDialog}
        handleClose={() => openConfirmDialog.set(false)}
        handleAgree={() => {
          openConfirmDialog.set(false);
          navigator(-1);
        }}
      />
      <StatusDialog mock={schedule} open={openStatusDialog} />
    </MainLayout>
  );
}
