import React from 'react';

import BaseButton from './BaseButton';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type ButtonProps = {
    readonly children: React.ReactNode, 
    type?: "button" | "submit" | "reset", 
    onClick?: ()=> void,
    disabled?: boolean,

}

export default function PrimaryResponsiveButton({
    type="button", 
    onClick =()=>{} , 
    disabled= false,  
    children,
}: ButtonProps) {    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"), {
        noSsr: true
    });
    return (
        <BaseButton  fullWidth={isMobile?true:false} size="small"  variant="contained" type={type} onClick={onClick} disabled={disabled}>
          {children}
        </BaseButton>
          
    )
}