import React from "react";
import Grid from "@mui/material/Grid2";
import CancelButton from "../../../../components/Buttons/CancelButton";
import ConfirmButton from "../../../../components/Buttons/ConfirmButton";
import InputField from "../../../../components/Field";
import { State, useHookstate } from "@hookstate/core";
import { ICitizen } from "../SearchCitizenTab";
import InputMask from "react-input-mask";
import dayjs from "dayjs";
import { Typography } from "@mui/material";

interface IKeywordRegisterTab {
  readonly citizenDetails: State<ICitizen | null>;
  readonly plate: string;
  readonly trackId: string;
  readonly trackChip: string;
  readonly value: State<number>;
  readonly citizen: string | null;
  readonly confirmAction: () => void;
  readonly brand: string | null;
}

export default function SummaryTab({
  citizen,
  citizenDetails,
  value,
  plate,
  trackId,
  brand,
  trackChip,
  confirmAction,
}: IKeywordRegisterTab) {
  return (
    <Grid container spacing={2} sx={{ width: "100%" }}>
      <Grid size={{ xs: 12, sm: 6, md: 3 }} sx={{ marginLeft: 1 }}>
        <Typography>
          <b>Nome:</b> {citizenDetails.get()?.name}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }} sx={{ marginLeft: 1 }}>
        <Typography>
          <b>CPF:</b>{" "}
          {citizenDetails
            .get()
            ?.cpf.replace(/(\d{3})(\d{3})(\d{3})(\d)/, "$1.$2.$3-$4")}
        </Typography>
      </Grid>

      <Grid size={{ xs: 12, sm: 6, md: 3 }} sx={{ marginLeft: 1 }}>
        <Typography>
          <b>Data de Nascimento:</b>{" "}
          {dayjs(citizenDetails.get()?.birthDate).format("DD/MM/YYYY")}
        </Typography>
      </Grid>

      <Grid size={{ xs: 12, sm: 6, md: 3 }} sx={{ marginLeft: 1 }}>
        <Typography>
          <b>Telefone:</b>{" "}
          {citizenDetails
            .get()
            ?.phone.replace(/(\d{2})(\d{5})(\d)/, "($1) $2-$3")}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }} sx={{ marginLeft: 1 }}>
        <Typography>
          <b>Placa do Veículo:</b> {plate}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }} sx={{ marginLeft: 1 }}>
        <Typography>
          <b>Código do Rastreador:</b> {trackId}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }} sx={{ marginLeft: 1 }}>
        <Typography>
          <b>Chip do Rastreador:</b> {trackChip}
        </Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6, md: 3 }} sx={{ marginLeft: 1 }}>
        <Typography>
          <b>Marca do Rastreador:</b> {brand ? brand : 'Não informado'}
        </Typography>
      </Grid>

      
      <Grid
        container
        size={12}
        sx={{ gap: 1 }}
        display={"flex"}
        justifyContent="flex-end"
        paddingTop={2}
      >
        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <CancelButton
            stepMode
            onCancelClick={() => value.set(value.get() - 1)}
          />
        </Grid>

        <Grid
          size={{ xs: 12, sm: 3, md: 2, lg: 1 }}
          display={"flex"}
          sx={{ justifyContent: "flex-end" }}
        >
          <ConfirmButton onConfirmClick={confirmAction} />
        </Grid>
      </Grid>
    </Grid>
  );
}
