import React, { ReactNode } from 'react';
  
type ContainerMarkProp = {
    color: string;
    readonly children: ReactNode;
}
export default function ContainerMarker({color = '#fff', children}: ContainerMarkProp) {
    return( 
        <div 
            style={{
                width:'100%', 
                height:'100%', 
                backgroundColor: color, 
                padding: '8px', 
                borderRadius: '50%', 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems:'center',
                position: 'relative'
               
                }}>
            {children}
           
            <div style={{
                    position: 'absolute',
                    left: '15.5%',
                    backgroundColor: 'transparent',
                    width: '40px',
                    bottom: '-55%'

                }}>
                <div style={{
                     borderTop: `20px solid ${color}`,
                     borderRight: '20px solid transparent',
                     borderBottom:'20px solid transparent',
                     borderLeft: '20px solid transparent',
                    
                }}>
                
            </div>
            </div>
        </div>
    )

}

