import { authFetch } from "../../../services/fetch";
import { IError } from "../../Citizen/services";
import { ITrack } from "../interface";

export async function createTracker({plate, brand, trackCode, userId, keyword, trackChip, image}: ITrack) {
    try {
        const response = await authFetch({
            url: '/tracker/create',
            data: {
                plate: plate,
                brand: brand,
                trackCode: trackCode, 
                keyword: keyword,
                userId: userId,
                chipCode: trackChip,
                image: image,
                // location: [45.1234, -89.4567]
            }
        })
        return {hasError: false, data: response.data}
    } catch (error) {
        console.error('~ createTracker: ', error);
        return {hasError: true, error: error as IError}
    }
}

export async function listTracker(idUser: any) {
    try {
        const response = await authFetch({
            url:  `/tracker/user/${idUser}`,
            method: "GET"
        })
        return {hasError: false, response}
    } catch (error) {
        console.error('~ listTracker: ', error);
        return {hasError: true, error: error as {title: string}}
    }
}

export async function getLastTrackPosition(trackCode: string) {
    try {
        const response = await authFetch({
            url:  `/tracker/last-position`,
            method: "POST",
            data: {
                trackCode: trackCode
            }
        })
        return response.data
    } catch (error) {
        console.error('~ getLastTrackPosition: ', error);
        throw error
    }
}