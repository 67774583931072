import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid2';
import { MobileStepper } from '@mui/material';
import { useAccreditedStep } from '../../../contexts/AccreditedStepContext';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import BaseButton from '../../../../../components/Buttons/BaseButton';
import SecondaryButton from '../../../../../components/Buttons/SecondaryButton';
import PrimaryButton from '../../../../../components/Buttons/PrimaryButton';


export default function MobileControlStepForm({onFinalize = ()=>{}, canMoveForward = true}) {
  const {handleBack, activeStep, lengthStep, isLastStep} = useAccreditedStep();
  const [canMove, setCanMove] = React.useState<boolean>(true);
  

  const getTypeButton = () => {
    return  !isLastStep? 
    <PrimaryButton  type='submit' disabled={ !canMove}>
      Avançar
      <KeyboardArrowRight />
    </PrimaryButton>
    :
    <PrimaryButton 
      disabled={!canMove}
      onClick={onFinalize}
      >
      Finalizar
      <KeyboardArrowRight />
    </PrimaryButton>
  } 
  useEffect(()=>{
    setCanMove(canMoveForward)
    //setCanMove(true)
  }, [canMoveForward])

  return (
      <MobileStepper
        variant="dots"
        steps={lengthStep}
        position="static"
        activeStep={activeStep}
        sx={{width: '100%', pl: 0, pr: 0}}
        nextButton={
          getTypeButton()
        }
        backButton={
          <SecondaryButton 
           
            onClick={handleBack} 
            disabled={activeStep === 0}
            >           
            <KeyboardArrowLeft />
            Voltar
          </SecondaryButton>
        }
      />
        
          
  );
}
