import { Typography } from "@mui/material";

export default function TermOfUse() {
  return (
    <>
      <Typography variant="body1" textAlign={"center"} fontWeight={'bold'}>
        TERMO DE AUTORIZAÇÃO PARA ACESSO AO RASTREAMENTO VEICULAR
      </Typography>
      <Typography variant="body2">
        Ao aceitar este termo, você, cidadão, está autorizando a Secretaria de
        Segurança Pública do Estado do Ceará e demais órgãos interligados a
        terem acesso ao arquivo de rastreamento do seu veículo. O acesso ao
        arquivo de rastreamento veicular será permitido após a comunicação da
        ocorrência de furto ou roubo por meio do canal 190, com a informação do
        seu nome, placa do veículo e a respectiva chave de acesso ao sistema de
        rastreio. O acesso ao arquivo de rastreamento será a partir das 72 horas
        anteriores ao dia e horário da comunicação da ocorrência. A
        responsabilidade pela manutenção, funcionamento, guarda e demais
        cuidados com o rastreador será inteiramente sua. Eventuais problemas
        elétricos, de funcionamento ou de qualquer outra natureza não poderão
        ser questionados junto à Secretaria de Segurança Pública do Estado do
        Ceará e demais órgãos interligados. Esta autorização é válida por um
        período indeterminado a partir da data de aceitação deste termo, podendo
        ser revogada a qualquer momento. Os dados e informações obtidos através
        dos arquivos do sistema de rastreamento veicular serão tratados com a
        devida confidencialidade e em conformidade com a legislação vigente
        sobre proteção de dados pessoais. Declaro estar ciente e de acordo com
        os termos acima descritos e autorizo o acesso ao arquivo do sistema de
        rastreamento do meu veículo conforme as condições estabelecidas.
      </Typography>
    </>
  );
}
