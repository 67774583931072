import React, {createContext, useEffect, useState} from 'react';
import {useAuth} from 'react-oidc-context';
import {io, type Socket} from 'socket.io-client';
import { socketEvents } from './helpers/socket';

interface SocketContextType {
 socket: Socket | null;
 socketIsConnected: boolean;
}

export const SocketContext = createContext<SocketContextType>({
 socket: null,
 socketIsConnected: false,
});

const socketEndpoint = () => {
    const baseUrl = 'https://moback.spocws.icu';
    // const baseUrl = 'http://localhost:8888';
    const [protocol, url] = baseUrl.split('://');
    const secure = (protocol === 'https');

    return secure ? `wss://${url}` : `ws://${url}`;
};

interface SocketContextProviderProps {
 children: React.ReactNode;
}

function SocketContextProvider({children}: SocketContextProviderProps) {
 const [socket, setSocket] = useState<Socket | null>(null);
 const [socketIsConnected, setSocketIsConnected] = useState(false);
 const auth = useAuth();

 useEffect(() => {
  if (auth?.isAuthenticated) {
   const initialSocket = io(socketEndpoint(), {
    transports: ['websocket', 'polling'],
    autoConnect: true,
    auth: {token: auth.user?.access_token},
   });

   setSocket(initialSocket);
   return () => {
    if (socket) socket.disconnect();
   };
  }
 }, [auth]);

 useEffect(() => {
  if (socket != null && !socketIsConnected) {
   socket.on('connect', () => {
    console.log('INFO', 'Socket conectado!');
    setSocketIsConnected(true);
   });

   socket.on("connect_error", (err) => {
    setSocketIsConnected(false); // prints the message associated with the error
   });

   // All events socket
   socketEvents(socket);
  }
 }, [socket, socketIsConnected]);

 return (
  <SocketContext.Provider value={{socket, socketIsConnected}}>
   {children}
  </SocketContext.Provider>
 );
}

export default SocketContextProvider;
