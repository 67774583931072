import { getUserInfoKeyCloak } from "../Authentication/AuthenticationCore";


export const getUserIdKeyCloak = async () => {
    const userStore = getUserInfoKeyCloak()
    if (userStore) {
      return userStore.profile.sub
    }
    return null
};

export const getFirtNameUserKeyCloak = async () => {
  const userStore = getUserInfoKeyCloak()
  if (userStore) {
    const userName = userStore?.profile?.name;
    const firtname = userName?.split(' ');
    return firtname ? firtname[0] : firtname
  }
  return null
};

