import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { UseFormGetValues, UseFormRegister, Controller, UseFormSetValue } from 'react-hook-form';

interface CrudTextFieldProps {
  readonly register: UseFormRegister<any>;
  readonly name: string;
  readonly getValues?: UseFormGetValues<any>;
  readonly control?: any;
  readonly label?: string;
  required?: boolean;
  messageError?: string;
  value?: string;
  readonly setValue: UseFormSetValue<any>,
  disabled?: boolean
  id?: string


}

export default function EmailFieldForm({ label, register, control = {}, name, required = false, messageError = "", value ='', setValue,  disabled = false, id = 'email_form' }: CrudTextFieldProps) {
  
  useEffect(()=> {
    if (value !== '' && value !== undefined && value !== null) {
      setValue(name, value)
    }
  }, [value])

  return (
    <Controller
      {...register(
        name,
        {
          required: { 
            value: required,
            message: messageError === '' ? 'Email é obrigatório' : messageError,
          },
          pattern: {
            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: 'Email é inválido',
          },
        }) 
      }
      name={name}
      control={control}
      defaultValue={value}
      render={({
        field,
        fieldState: { error },
      }) => {
        
        return <TextField
          helperText={error && required? error.message : null}
          error={required?!!error: false}
          {...field}
          label={label}
          name={name}
          size="small"
          fullWidth
          id={id}
          sx={{ 
            mt: 0, 
            borderRadius: '50%',
            '& .MuiInputBase-root' : {
            border: '0.2px',
            borderRadius: '10px',
            '& input':{
              padding: 1
            }
          } 
          }}
          disabled={disabled}
        />
      }}
    />
  )
}

