import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import InputField from '../../Field';
import { ICitizen } from '../../../features/Tracks/components/SearchCitizenTab';

interface CitizenProps {
    readonly onStatusChange: (value: string) => void;
    readonly status?: string;
    readonly disabled?: boolean
    readonly required?: boolean
    readonly error?: boolean
}

export default function FilterAutocompleteStatusScheduling({ onStatusChange, status, required = false, error = false }: CitizenProps) {
    const [options, setOptions] = useState<readonly string[]>(['Cidadão não compareceu', 'Falha no rastreador', 'Outros']);
    const [value, setValue] = useState<any>(status);

    useEffect(() => {
        setValue(status)
    }, [status])

    return (
        <FormControl fullWidth size='small' >
            <Autocomplete
                id='filter-status'
                fullWidth
                options={options}
                value={value}
                noOptionsText={'Sem opção'}
                size='small'
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option}
                onChange={(_, status) => {
                    setValue(status)
                    onStatusChange(status)
                }}
                renderInput={(params) => (
                    <InputField
                        {...params}
                        label={'Motivo do Cancelamento'}
                        required
                    />
                )}
                // disabled
            />
        </FormControl>
    )
}
